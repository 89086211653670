import React, { useCallback, useLayoutEffect, useRef } from "react";
import { Button, Icon, Card } from "@stellar/design-system";
import "./styles.scss";

export const Filter = ({
  isVisible,
  onClick,
  activeFilterCount,
  children,
}: {
  isVisible: boolean;
  onClick: () => void;
  activeFilterCount: number;
  children: React.ReactNode;
}) => {
  const filterEl = useRef<HTMLDivElement | null>(null);
  const filterButtonEl = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      // Do nothing if clicking filter button
      if (
        event.target === filterButtonEl?.current ||
        filterButtonEl?.current?.contains(event.target as Node)
      ) {
        return;
      }

      if (!filterEl?.current?.contains(event.target as Node)) {
        onClick();
      }
    },
    [onClick],
  );

  useLayoutEffect(() => {
    if (isVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible, handleClickOutside]);

  const renderButtonLabel = () => {
    if (activeFilterCount === 0) {
      return "Filter";
    }

    return `Filter (${activeFilterCount})`;
  };

  return (
    <div className="Filter">
      <div ref={filterButtonEl}>
        <Button
          iconLeft={<Icon.Filter />}
          variant={Button.variant.tertiary}
          onClick={onClick}
        >
          {renderButtonLabel()}
        </Button>
      </div>

      {isVisible ? (
        <div className="FilterContainer" ref={filterEl}>
          <Card>{children}</Card>
        </div>
      ) : null}
    </div>
  );
};
