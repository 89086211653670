import { Input, Button } from "@stellar/design-system";
import { useDispatch } from "react-redux";
import { AppDispatch } from "config/store";

import {
  fetchDisbursementsAction,
  setFiltersAction,
  clearFiltersAction,
} from "ducks/disbursements";
import { useRedux } from "hooks/useRedux";
import { AnyObject } from "types";

export const FiltersDisbursements = ({ onClose }: { onClose: () => void }) => {
  const { disbursements } = useRedux("disbursements");
  const { filters } = disbursements;
  const dispatch: AppDispatch = useDispatch();

  const applyFilters = (selectedFilters: AnyObject) => {
    dispatch(fetchDisbursementsAction(selectedFilters));
    onClose();
  };

  const clearFilters = () => {
    dispatch(clearFiltersAction());
    applyFilters({});
  };

  const updateFilter = (filterId: string, value: string | boolean) => {
    dispatch(
      setFiltersAction({
        [filterId]: value,
      }),
    );
  };

  const convertDateToDatetime = (date: string) => `${date}T00:00:00Z`;
  const getDateFromDateTime = (dateString: string) => dateString.split("T")[0];

  return (
    <>
      <div className="Filter__row">
        <label>Total Disbursed</label>
        <div className="Filter__row__fields">
          <Input
            id="total_amount_min"
            value={filters.total_amount_min || ""}
            onChange={(e) =>
              updateFilter(e.target.id, e.target.value.toString())
            }
            type="number"
            label="Min"
            leftElement="$"
          />

          <Input
            id="total_amount_max"
            value={filters.total_amount_max || ""}
            onChange={(e) =>
              updateFilter(e.target.id, e.target.value.toString())
            }
            type="number"
            label="Max"
            leftElement="$"
          />
        </div>
      </div>

      <div className="Filter__row">
        <label>Disbursement Date</label>
        <div className="Filter__row__date">
          <Input
            id="started_at_after"
            type="date"
            value={
              filters.started_at_after
                ? getDateFromDateTime(filters.started_at_after)
                : ""
            }
            onChange={(e) =>
              updateFilter(e.target.id, convertDateToDatetime(e.target.value))
            }
            label="From"
          />
        </div>
        <div className="Filter__row__date">
          <Input
            id="started_at_before"
            type="date"
            value={
              filters.started_at_before
                ? getDateFromDateTime(filters.started_at_before)
                : ""
            }
            onChange={(e) =>
              updateFilter(e.target.id, convertDateToDatetime(e.target.value))
            }
            label="To"
          />
        </div>
      </div>

      <div className="Filter__buttons">
        <Button variant={Button.variant.secondary} onClick={clearFilters}>
          Reset
        </Button>
        <Button onClick={() => applyFilters(filters)}>Apply</Button>
      </div>
    </>
  );
};
