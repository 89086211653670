import { Input, Button, Checkbox } from "@stellar/design-system";
import { useDispatch } from "react-redux";
import { AppDispatch } from "config/store";

import {
  fetchBeneficiariesAction,
  setFiltersAction,
  clearFiltersAction,
} from "ducks/beneficiaries";
import { useRedux } from "hooks/useRedux";
import { AnyObject } from "types";

export const FiltersBeneficiaries = ({ onClose }: { onClose: () => void }) => {
  const { beneficiaries } = useRedux("beneficiaries");
  const { filters } = beneficiaries;
  const dispatch: AppDispatch = useDispatch();

  const applyFilters = (selectedFilters: AnyObject) => {
    dispatch(fetchBeneficiariesAction(selectedFilters));
    onClose();
  };

  const clearFilters = () => {
    dispatch(clearFiltersAction());
    applyFilters({});
  };

  const updateFilter = (filterId: string, value: string | boolean) => {
    dispatch(
      setFiltersAction({
        [filterId]: value,
      }),
    );
  };

  return (
    <>
      <div className="Filter__row">
        <label>Num of Received Payments</label>
        <div className="Filter__row__fields">
          <Input
            id="number_received_payments"
            type="number"
            value={filters.number_received_payments || ""}
            onChange={(e) =>
              updateFilter(e.target.id, e.target.value.toString())
            }
          />
        </div>
      </div>

      <div className="Filter__row">
        <label>Total Received</label>
        <div className="Filter__row__fields">
          <Input
            id="amount_received_min"
            type="number"
            label="Min"
            leftElement="$"
            value={filters.amount_received_min || ""}
            onChange={(e) =>
              updateFilter(e.target.id, e.target.value.toString())
            }
          />

          <Input
            id="amount_received_max"
            type="number"
            label="Max"
            leftElement="$"
            value={filters.amount_received_max || ""}
            onChange={(e) =>
              updateFilter(e.target.id, e.target.value.toString())
            }
          />
        </div>
      </div>

      <div className="Filter__row">
        <Checkbox
          id="failed_payments"
          label="Only beneficiaries with failed payments"
          checked={!!filters.failed_payments}
          onChange={(e) => updateFilter(e.target.id, e.target.checked)}
        />
      </div>

      {/* TODO: add status checkboxes */}

      <div className="Filter__buttons">
        <Button variant={Button.variant.secondary} onClick={clearFilters}>
          Reset
        </Button>
        <Button onClick={() => applyFilters(filters)}>Apply</Button>
      </div>
    </>
  );
};
