import { ItemStatus, RecordStatus, WithdrawalStatus } from "types";

export const getItemStatus = (
  status: RecordStatus,
  withdrawalStatus: WithdrawalStatus,
): ItemStatus => {
  switch (withdrawalStatus) {
    case "FULLY_WITHDRAWN":
      return "fullCashOut";
    case "PARTIALLY_WITHDRAWN":
      return "partialCashOut";
    // If there is no withdrawal status, it means there is no withdrawal
    // associated with it yet. Fall through and use payment status instead.
    case "NONE":
    default:
      break;
  }
  switch (status) {
    case "SUCCESS":
      return "success";
    case "FAILURE":
      return "failed";
    case "PENDING_FUNDS":
      return "pendingFunds";
    case "REQUESTED":
    case "PENDING":
    default:
      return "pending";
  }
};
