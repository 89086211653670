import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "config/store";
import { fetchBeneficiaries } from "helpers/fetchBeneficiaries";
import { formatBeneficiaryRecords } from "helpers/formatBeneficiaryRecords";
import { removeFalsyKeys } from "helpers/removeFalsyKeys";
import { getErrorMessage } from "helpers/getErrorMessage";
import { endSessionIfTokenInvalid } from "helpers/endSessionIfTokenInvalid";
import {
  ActionStatus,
  RejectMessage,
  BeneficiariesInitialState,
  BeneficiaryItem,
  BeneficiaryOptions,
  AnyObject,
} from "types";

export const fetchBeneficiariesAction = createAsyncThunk<
  {
    items: BeneficiaryItem[];
    count: number;
    cursor: string | null;
  },
  BeneficiaryOptions | undefined,
  { rejectValue: RejectMessage; state: RootState }
>(
  "beneficiaries/fetchBeneficiariesAction",
  async (options, { rejectWithValue, getState, dispatch }) => {
    const { token } = getState().userAccount;

    try {
      const response = await fetchBeneficiaries({
        token,
        options,
      });

      endSessionIfTokenInvalid(response, dispatch);
      const { accounts, count, cursor } = response;

      return {
        items: formatBeneficiaryRecords(accounts),
        count,
        cursor,
      };
    } catch (error: any) {
      return rejectWithValue({
        errorString: getErrorMessage(error),
      });
    }
  },
);

export const fetchMoreBeneficiariesAction = createAsyncThunk<
  {
    items: BeneficiaryItem[];
    cursor: string | null;
  },
  undefined,
  { rejectValue: RejectMessage; state: RootState }
>(
  "beneficiaries/fetchMoreBeneficiariesAction",
  async (_, { rejectWithValue, getState, dispatch }) => {
    const { token } = getState().userAccount;
    const { cursor: currentCursor, search, filters } = getState().beneficiaries;
    const options = {
      ...(currentCursor ? { cursor: currentCursor } : {}),
      search,
      ...filters,
    };

    try {
      const response = await fetchBeneficiaries({
        token,
        options,
      });

      endSessionIfTokenInvalid(response, dispatch);
      const { accounts, cursor } = response;

      return {
        items: formatBeneficiaryRecords(accounts),
        cursor,
      };
    } catch (error: any) {
      return rejectWithValue({
        errorString: getErrorMessage(error),
      });
    }
  },
);

const initialState: BeneficiariesInitialState = {
  items: [],
  count: 0,
  cursor: null,
  filters: {},
  search: "",
  status: undefined,
  errorString: undefined,
};

const beneficiariesSlice = createSlice({
  name: "beneficiaries",
  initialState,
  reducers: {
    resetBeneficiariesAction: () => initialState,
    setFiltersAction: (state, action: PayloadAction<AnyObject>) => {
      state.filters = removeFalsyKeys({ ...state.filters, ...action.payload });
    },
    clearFiltersAction: (state) => {
      state.filters = {};
    },
    setSearchAction: (state, action) => {
      state.search = action.payload;
    },
    clearSearchAction: (state) => {
      state.search = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchBeneficiariesAction.pending,
      (state = initialState) => {
        state.status = ActionStatus.PENDING;
      },
    );
    builder.addCase(fetchBeneficiariesAction.fulfilled, (state, action) => {
      state.items = action.payload.items;
      state.count = action.payload.count;
      state.cursor = action.payload.cursor;
      state.status = ActionStatus.SUCCESS;
      state.errorString = "";
    });
    builder.addCase(fetchBeneficiariesAction.rejected, (state, action) => {
      state.status = ActionStatus.ERROR;
      state.errorString = action.payload?.errorString;
    });

    builder.addCase(
      fetchMoreBeneficiariesAction.pending,
      (state = initialState) => {
        state.status = ActionStatus.PENDING;
      },
    );
    builder.addCase(fetchMoreBeneficiariesAction.fulfilled, (state, action) => {
      state.items = [...state.items, ...action.payload.items];
      state.cursor = action.payload.cursor;
      state.status = ActionStatus.SUCCESS;
      state.errorString = "";
    });
    builder.addCase(fetchMoreBeneficiariesAction.rejected, (state, action) => {
      state.status = ActionStatus.ERROR;
      state.errorString = action.payload?.errorString;
    });
  },
});

export const beneficiariesSelector = (state: RootState) => state.beneficiaries;

export const { reducer } = beneficiariesSlice;
export const {
  resetBeneficiariesAction,
  setFiltersAction,
  clearFiltersAction,
  setSearchAction,
  clearSearchAction,
} = beneficiariesSlice.actions;
