import { Tag } from "@stellar/design-system";
import { BeneficiaryStatus } from "types";

export const BeneficiaryStatusTag = ({
  status,
}: {
  status: BeneficiaryStatus;
}) => {
  switch (status) {
    case "REGISTERED":
      return (
        <div className="StatusTag">
          <Tag>Not registered</Tag>
        </div>
      );
    case "LINK_SENT":
      return (
        <div className="StatusTag">
          <Tag>SMS sent, not reg.</Tag>
        </div>
      );
    case "READY":
      return (
        <div className="StatusTag">
          <Tag variant={Tag.variant.error}>Ready, never paid</Tag>
        </div>
      );
    case "PAID":
      return (
        <div className="StatusTag">
          <Tag variant={Tag.variant.warning}>Paid, no cash-out</Tag>
        </div>
      );
    case "PARTIALLY_CASHED_OUT":
      return (
        <div className="StatusTag">
          <Tag variant={Tag.variant.highlight}>Part. cashed-out</Tag>
        </div>
      );
    case "FULLY_CASHED_OUT":
      return (
        <div className="StatusTag">
          <Tag variant={Tag.variant.success}>Fully cashed-out</Tag>
        </div>
      );
    default:
      return null;
  }
};
