import { LOCALE } from "constants/settings";

export const formatDateTime = (date: string) => {
  const d = new Date(date);

  const timeString = d.toLocaleTimeString(LOCALE, {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  return `${formatDate(date)} ${timeString}`;
};

export const formatDate = (date: string) => {
  const d = new Date(date);

  return d.toLocaleDateString(LOCALE, {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};
