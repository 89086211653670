import React, { useState, useEffect, useRef } from "react";
import { Card, Table, Pagination, Loader } from "@stellar/design-system";
import { Link } from "react-router-dom";

import { InfoLabel, BENEFICIARIES_PAGE_SIZE } from "constants/settings";
import { BeneficiaryStatusTag } from "components/BeneficiaryStatusTag";
import { StellarAddress } from "components/StellarAddress";
import { currency } from "helpers/formatAmount";
import { formatUkrainianPhoneNumber } from "helpers/formatUkrainianPhoneNumber";
import { getCurrentPageItems } from "helpers/getCurrentPageItems";
import { BeneficiaryItem, ActionStatus } from "types";

type BeneficiariesProps = {
  count: number;
  beneficiaries: BeneficiaryItem[];
  status: ActionStatus | undefined;
  header: React.ReactNode;
  emptyMessage?: string;
  errorMessage?: string;
  onFetchMore: () => void;
};

export const Beneficiaries = ({
  count,
  beneficiaries,
  status,
  header,
  emptyMessage,
  errorMessage,
  onFetchMore,
}: BeneficiariesProps) => {
  const [currentPage, setCurrentPage] = useState<number | undefined>(undefined);
  const [currentItems, setCurrentItems] = useState<BeneficiaryItem[]>([]);

  const isLoading = status === ActionStatus.PENDING;
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!isLoading) {
      const items = getCurrentPageItems({
        currentIndex: currentPage,
        allItems: beneficiaries,
        maxCount: count,
        pageSize: BENEFICIARIES_PAGE_SIZE,
        onFetchMore,
      });

      if (items) {
        setCurrentItems(items);

        if (currentPage) {
          containerRef?.current?.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }, [isLoading, count, currentPage, onFetchMore, beneficiaries]);

  const labels = [
    {
      id: "phoneNumber",
      label: <span title={InfoLabel.PHONE_NUMBER}>Phone number</span>,
    },
    {
      id: "key",
      label: <span title={InfoLabel.ACCOUNT_KEY}>Account key</span>,
    },
    {
      id: "numTotalPayments",
      label: <span title={InfoLabel.TOTAL_PAYM}>Total payments</span>,
      sortBy: true,
    },
    {
      id: "numSuccessfulPayments",
      label: <span title={InfoLabel.SUCCESSFUL_PAYM}>Successful payments</span>,
      sortBy: true,
    },
    {
      id: "numFailedPayments",
      label: <span title={InfoLabel.FAILED_PAYM}>Failed payments</span>,
      sortBy: true,
    },
    {
      id: "amountTotalReceived",
      label: <span title={InfoLabel.TOTAL_RECEIVED}>Total received</span>,
      sortBy: true,
    },
    {
      id: "status",
      label: <span title={InfoLabel.ACCOUNT_STATUS}>Status</span>,
      sortBy: true,
    },
  ];

  const renderRow = (item: BeneficiaryItem) => (
    <React.Fragment key={item.id}>
      <td className="DetailsTable__nowrap">
        <Link
          to={`/beneficiaries/${item.id}`}
          className="TextLink TextLink--primary"
        >
          {formatUkrainianPhoneNumber(item.phoneNumber) || "-"}
        </Link>
      </td>
      <td className="DetailsTable__nowrap">
        <StellarAddress address={item.key} />
      </td>
      <td className="DetailsTable__narrow">{item.numTotalPayments}</td>
      <td className="DetailsTable__narrow">{item.numSuccessfulPayments}</td>
      <td className="DetailsTable__narrow">
        <span className={`${item.numFailedPayments > 0 ? "error" : ""}`}>
          {item.numFailedPayments}
        </span>
      </td>
      <td className="DetailsTable__nowrap">
        {item.amountTotalReceived
          ? currency.format(Number(item.amountTotalReceived))
          : "-"}
      </td>
      <td>
        <BeneficiaryStatusTag status={item.accountStatus} />
      </td>
    </React.Fragment>
  );

  return (
    <div ref={containerRef}>
      {header}

      {count === 0 && isLoading ? (
        <div className="InitialLoader">
          <Loader size="3rem" />
        </div>
      ) : null}

      {count || emptyMessage ? (
        <div className="DetailsTable">
          <Card>
            {errorMessage ? (
              <div className="error">{errorMessage}</div>
            ) : (
              <>
                <Table
                  id="beneficiaries"
                  data={currentItems}
                  columnLabels={labels}
                  renderItemRow={renderRow}
                  breakpoint={900}
                  hideNumberColumn
                  isLoading={isLoading}
                  emptyMessage={emptyMessage}
                />
                <Pagination
                  pageSize={BENEFICIARIES_PAGE_SIZE}
                  itemCount={count}
                  currentPage={currentPage || 1}
                  setCurrentPage={setCurrentPage}
                />
              </>
            )}
          </Card>
        </div>
      ) : null}
    </div>
  );
};
