import { DisbursementItem, DisbursementRecord } from "types";

export const formatDisbursementRecords = (
  records: DisbursementRecord[],
): DisbursementItem[] =>
  records.map((r) => ({
    id: r.id,
    startedAt: r.requested_at,
    numBeneficiaries: r.total_account_num,
    numSuccessfulPayments: r.payment_stats.successful.count,
    numPendingPayments:
      Number(r.payment_stats.pending.count) +
      Number(r.payment_stats.requested.count),
    numFailedPayments: r.payment_stats.failed.count,
    amountDisbursed: r.payment_stats.successful.amount,
    amountTotal: r.payment_stats.total.amount,
  }));
