import { Navigate, useLocation } from "react-router-dom";
import { useRedux } from "hooks/useRedux";
import { LOCAL_STORAGE_KEY, SESSION_EXPIRED } from "constants/settings";

export const PrivateRoute = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const { userAccount } = useRedux("userAccount");
  const location = useLocation();

  // Checking for session token to avoid unwanted redirect to "/"
  const hasSessionToken = Boolean(localStorage.getItem(LOCAL_STORAGE_KEY));
  const isSessionExpired = userAccount.errorString === SESSION_EXPIRED;

  return userAccount.isAuthenticated ||
    (hasSessionToken && !isSessionExpired) ? (
    children
  ) : (
    <Navigate
      to={{
        pathname: "/",
        search: location.search,
      }}
    />
  );
};
