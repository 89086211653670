import {
  Layout,
  ToggleDarkMode,
  TextLink,
  Logo,
  Tag,
} from "@stellar/design-system";
import { PROJECT_NAME } from "constants/settings";
import { PaymentsSwitch } from "components/PaymentsSwitch";
import "./styles.scss";

type PageHeaderProps = {
  username?: string;
  onSignOut?: () => void;
  showPaymentsSwitch?: boolean;
};

export const PageHeader = ({
  username,
  onSignOut,
  showPaymentsSwitch,
}: PageHeaderProps) => (
  <div className="Layout__header PageHeader">
    <Layout.Inset>
      {/* Left */}
      <div className="Layout__header--left">
        <a className="PageHeader__logo" href="./" rel="noreferrer noopener">
          {/* TODO: update logo */}
          <Logo.Stellar />
        </a>

        {showPaymentsSwitch ? <PaymentsSwitch /> : null}
      </div>

      {/* Right */}
      <div className="Layout__header--right">
        {username ? <Tag>{username}</Tag> : null}

        {onSignOut ? (
          <TextLink id="sign-out-button" role="button" onClick={onSignOut}>
            Sign out
          </TextLink>
        ) : null}

        <ToggleDarkMode storageKeyId={`stellarTheme:${PROJECT_NAME}`} />
      </div>
    </Layout.Inset>
  </div>
);
