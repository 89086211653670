import React, { useEffect, useState, useRef } from "react";
import {
  Layout,
  Card,
  Table,
  Pagination,
  Loader,
} from "@stellar/design-system";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { AppDispatch } from "config/store";
import {
  fetchDisbursementsAction,
  fetchMoreDisbursementsAction,
  setSearchAction,
  clearSearchAction,
} from "ducks/disbursements";
import { TabNavigation } from "components/TabNavigation";
import { TableHeader } from "components/TableHeader";
import { FiltersDisbursements } from "components/FiltersDisbursements";
import { Search } from "components/Search";
import { Filter } from "components/Filter";

import {
  HOME_TABS,
  InfoLabel,
  DISBURSEMENTS_PAGE_SIZE,
} from "constants/settings";
import { useRedux } from "hooks/useRedux";
import { useScrollToTop } from "hooks/useScrollToTop";
import { currency } from "helpers/formatAmount";
import { formatDateTime } from "helpers/formatDate";
import { getPluralizedText } from "helpers/getPluralizedText";
import { getCurrentPageItems } from "helpers/getCurrentPageItems";
import { ActionStatus, DisbursementItem } from "types";

export const Disbursements = () => {
  const { disbursements } = useRedux("disbursements");
  const { count, disbursements: items } = disbursements;

  const [currentPage, setCurrentPage] = useState<number | undefined>(undefined);
  const [currentItems, setCurrentItems] = useState<DisbursementItem[]>([]);
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);

  const dispatch: AppDispatch = useDispatch();

  const activeFilterCount = Object.keys(disbursements.filters).length;
  const isLoading = disbursements.status === ActionStatus.PENDING;
  const isSearching = Boolean(disbursements.search) && isLoading;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const isSearchOrFilter = disbursements.search || activeFilterCount > 0;

  useScrollToTop();

  useEffect(() => {
    dispatch(fetchDisbursementsAction());
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading) {
      const pageItems = getCurrentPageItems({
        currentIndex: currentPage,
        allItems: items,
        maxCount: count,
        pageSize: DISBURSEMENTS_PAGE_SIZE,
        onFetchMore: () => dispatch(fetchMoreDisbursementsAction()),
      });

      if (pageItems) {
        setCurrentItems(pageItems);

        if (currentPage) {
          containerRef.current?.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }, [isLoading, count, currentPage, items, dispatch]);

  const labels = [
    {
      id: "id",
      label: <span title={InfoLabel.DISB_ID}>ID</span>,
    },
    {
      id: "startedAt",
      label: <span title={InfoLabel.DISB_CREATED_AT}>Created At</span>,
    },
    {
      id: "numBeneficiaries",
      label: (
        <span title={InfoLabel.DISB_NUM_OF_BENEFICIARIES}>
          # of Beneficiaries
        </span>
      ),
      sortBy: true,
    },
    {
      id: "numSuccessfulPayments",
      label: (
        <span title={InfoLabel.DISB_SUCCESSFUL_PAYM}>Successful payments</span>
      ),
      sortBy: true,
    },
    {
      id: "numPendingPayments",
      label: <span title={InfoLabel.REMAINING_PAYM}>Remaining payments</span>,
      sortBy: true,
    },
    {
      id: "numFailedPayments",
      label: <span title={InfoLabel.DISB_FAILED_PAYM}>Failed payments</span>,
      sortBy: true,
    },
    {
      id: "amountDisbursed",
      label: <span title={InfoLabel.DISB_DISBURSED}>Disbursed</span>,
      sortBy: true,
    },
    {
      id: "amountTotal",
      label: <span title={InfoLabel.DISB_TOTAL}>Total</span>,
      sortBy: true,
    },
  ];

  const handleCloseFilters = () => {
    setIsFiltersVisible(!isFiltersVisible);
  };

  const handleSearchSubmit = (searchText: string) => {
    dispatch(setSearchAction(searchText));
    dispatch(
      fetchDisbursementsAction({
        search: searchText,
      }),
    );
  };

  const handleSearchClear = () => {
    dispatch(clearSearchAction());
    dispatch(fetchDisbursementsAction());
  };

  const getNoDataMessage = () => {
    if (isLoading) {
      return "";
    }

    if (disbursements.search) {
      return `No results for "${disbursements.search}"`;
    }

    if (activeFilterCount > 0) {
      return "No results for the selected filters";
    }

    return "There are no disbursements yet";
  };

  const renderRow = (item: DisbursementItem) => (
    <React.Fragment key={item.id}>
      <td className="DetailsTable__disbursId">
        <Link
          to={`/disbursements/${item.id}/beneficiaries`}
          className="TextLink TextLink--primary"
        >
          <span className="DetailsTable__ellipsis" title={item.id || ""}>
            {item.id}
          </span>
        </Link>
      </td>
      <td className="DetailsTable__nowrap">
        {item.startedAt ? formatDateTime(item.startedAt) : ""}
      </td>
      <td className="DetailsTable__narrow">{item.numBeneficiaries}</td>
      <td className="DetailsTable__narrow">{item.numSuccessfulPayments}</td>
      <td className="DetailsTable__narrow">{item.numPendingPayments}</td>
      <td className="DetailsTable__narrow">
        <span className={`${item.numFailedPayments > 0 ? "error" : ""}`}>
          {item.numFailedPayments}
        </span>
      </td>
      <td className="DetailsTable__nowrap">
        {currency.format(Number(item.amountDisbursed))}
      </td>
      <td className="DetailsTable__nowrap">
        {currency.format(Number(item.amountTotal))}
      </td>
    </React.Fragment>
  );

  return (
    <Layout.Inset>
      <TabNavigation tabs={HOME_TABS} />

      <div ref={containerRef}>
        <TableHeader
          title={getPluralizedText({
            count,
            singular: "disbursement",
            plural: "disbursements",
          })}
          actionItems={
            <>
              <Search
                id="disbursements-search"
                placeholder="Search by disbursement ID"
                onSubmit={handleSearchSubmit}
                onClear={handleSearchClear}
                isLoading={isSearching}
              />

              <Filter
                isVisible={isFiltersVisible}
                onClick={handleCloseFilters}
                activeFilterCount={activeFilterCount}
              >
                <FiltersDisbursements onClose={handleCloseFilters} />
              </Filter>
            </>
          }
        />

        {count === 0 && isLoading ? (
          <div className="InitialLoader">
            <Loader size="3rem" />
          </div>
        ) : null}

        {count || isSearchOrFilter ? (
          <div className="DetailsTable">
            <Card>
              {disbursements.status === ActionStatus.ERROR ? (
                <div className="error">{disbursements.errorString}</div>
              ) : (
                <>
                  <Table
                    id="disbursements"
                    data={currentItems}
                    columnLabels={labels}
                    renderItemRow={renderRow}
                    breakpoint={900}
                    hideNumberColumn
                    isLoading={isLoading}
                    emptyMessage={getNoDataMessage()}
                  />
                  <Pagination
                    pageSize={DISBURSEMENTS_PAGE_SIZE}
                    itemCount={count}
                    currentPage={currentPage || 1}
                    setCurrentPage={setCurrentPage}
                  />
                </>
              )}
            </Card>
          </div>
        ) : null}
      </div>
    </Layout.Inset>
  );
};
