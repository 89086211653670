import { formatBeneficiaryItem } from "helpers/formatBeneficiaryItem";
import { BeneficiaryItem, BeneficiaryRecord } from "types";

export const formatBeneficiaryRecords = (
  records: BeneficiaryRecord[] = [],
): BeneficiaryItem[] => {
  const formattedRecords: BeneficiaryItem[] = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const record of records) {
    // eslint-disable-next-line no-await-in-loop
    formattedRecords.push(formatBeneficiaryItem(record));
  }

  return formattedRecords;
};
