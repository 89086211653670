import { useState } from "react";
import { Icon, IconButton, Loader } from "@stellar/design-system";
import "./styles.scss";

interface SearchProps {
  id: string;
  placeholder: string;
  onSubmit: (text: string) => void;
  onClear: () => void;
  isLoading: boolean;
}

export const Search = ({
  id,
  placeholder,
  onSubmit,
  onClear,
  isLoading,
}: SearchProps) => {
  const [searchText, setSearchText] = useState("");

  const handleClear = () => {
    setSearchText("");
    onClear();
  };

  return (
    <div className="Search Input">
      <form className="Input__container">
        <div className="Input__wrapper">
          <input
            id={id}
            placeholder={placeholder}
            onChange={(e) => setSearchText(e.target.value || "")}
            value={searchText}
          />
        </div>

        {searchText ? (
          <IconButton
            icon={<Icon.X />}
            altText="Clear search"
            customColor="var(--pal-text-primary)"
            customSize="1.3rem"
            onClick={handleClear}
            type="button"
          />
        ) : null}

        <button
          className="Search__button"
          onClick={() => onSubmit(searchText)}
          disabled={isLoading || !searchText}
          type="submit"
        >
          {isLoading ? <Loader /> : <Icon.Search />}
        </button>
      </form>
    </div>
  );
};
