export const PROJECT_NAME = "reliefDashboard";
export const LOCALE = "en-US";
export const RESET_STORE_ACTION_TYPE = "RESET";
export const API_URL = window._env_.API_URL;
export const STELLAR_EXPERT_URL = window._env_.STELLAR_EXPERT_URL;

export const USE_SSO = window._env_.USE_SSO;
export const OIDC_AUTHORITY = window._env_.OIDC_AUTHORITY;
export const OIDC_CLIENT_ID = window._env_.OIDC_CLIENT_ID;
export const OIDC_REDIRECT_URI = window._env_.OIDC_REDIRECT_URI;
export const OIDC_SCOPE = window._env_.OIDC_SCOPE;
export const OIDC_USERNAME_MAPPING = window._env_.OIDC_USERNAME_MAPPING;

export const GENERIC_ERROR_MESSAGE = "Something went wrong, please try again";
export const SESSION_EXPIRED = "SESSION EXPIRED";
export const LOCAL_STORAGE_KEY = "relief_session";

export const BENEFICIARIES_PAGE_SIZE = 50;
export const DISBURSEMENTS_PAGE_SIZE = 20;
export const PAYMENTS_PAGE_SIZE = 50;

export const HOME_TABS = [
  {
    id: "beneficiaries",
    link: "/beneficiaries",
    label: "Beneficiaries",
  },
  {
    id: "disbursements",
    link: "/disbursements",
    label: "Disbursements",
  },
];

export enum InfoLabel {
  PHONE_NUMBER = "Beneficiary’s phone number",
  ACCOUNT_KEY = "Beneficiary’s Stellar account public key",
  TOTAL_PAYM = "Total number of payments requested to this beneficiary - includes pending, completed or failed",
  REMAINING_PAYM = "Remaining payments - includes pending and requested",
  FAILED_PAYM = "Total number of failed payments to this beneficiary",
  SUCCESSFUL_PAYM = "Total number of successful payments sent to this beneficiary",
  TOTAL_RECEIVED = "Total amount this beneficiary received",
  CURRENT_BALANCE = "Current balance",
  WITHDRAWN_BALANCE = "Withdrawn balance",
  ACCOUNT_STATUS = "Current status of the beneficiary",
  EMAIL = "Beneficiary’s e-mail address provided by the beneficiary when registering with the wallet app",
  DATE_OF_BIRTH = "Beneficiary’s month and year of birth",
  SMS_LAST_SENT = "Last time an SMS was sent to the beneficiary",
  DUSBURS_ID = "Identification number of the disbursement that this payment belongs to",
  COMPLETED_AT = "Date and time that the payment was completed",
  CIRCLE_TRANSFER_ID = "Circle’s identification of the payment",
  STELLAR_TRANSACTION = "Transaction number on the blockchain",
  PAYMENT_AMOUNT = "Amount paid to the beneficiary",
  PAYMENT_STATUS = "Current status of this payment",
  DISB_ID = "Identification number of the disbursement",
  DISB_CREATED_AT = "Date and time of when the disbursement was imported",
  DISB_NUM_OF_BENEFICIARIES = "Total number of beneficiaries in the disbursement",
  DISB_SUCCESSFUL_PAYM = "Total number of successful payments sent to beneficiaries in the disbursement",
  DISB_PENDING_PAYM = "Total number of pending payments sent to beneficiaries in the disbursement",
  DISB_FAILED_PAYM = "Total number of failed payments sent to beneficiaries in the disbursement",
  DISB_DISBURSED = "Total amount already paid to beneficiaries",
  DISB_WITHDRAWN = "Total amount withdrawn by beneficiaries",
  DISB_TOTAL = "Sum of all intended payments, both successful or pending",
  DISB_BENEFICIARIES_SMS_WAS_SENT_TO = "Total number of beneficiaries that received at least one SMS",
  DISB_VIBRANT_ACCOUNTS_CREATED = "Total number of beneficiaries who created a Vibrant account",
  DISB_AVERAGE_PAYMENT_PER_ACCOUNT = "Total amount in this disbursement divided by the number of accounts",
}
