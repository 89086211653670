import { useEffect, useState } from "react";
import { Layout } from "@stellar/design-system";
import { useDispatch } from "react-redux";

import { AppDispatch } from "config/store";
import { TableHeader } from "components/TableHeader";
import { FiltersBeneficiaries } from "components/FiltersBeneficiaries";
import { TabNavigation } from "components/TabNavigation";
import { Filter } from "components/Filter";
import { Search } from "components/Search";
import { Beneficiaries as BeneficiariesTable } from "components/Beneficiaries";

import { HOME_TABS } from "constants/settings";
import {
  fetchBeneficiariesAction,
  fetchMoreBeneficiariesAction,
  setSearchAction,
  clearSearchAction,
} from "ducks/beneficiaries";
import { useRedux } from "hooks/useRedux";
import { useScrollToTop } from "hooks/useScrollToTop";
import { getPluralizedText } from "helpers/getPluralizedText";
import { ActionStatus } from "types";

export const Beneficiaries = () => {
  const { beneficiaries } = useRedux("beneficiaries");
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  const activeFilterCount = Object.keys(beneficiaries.filters).length;
  const isLoading = beneficiaries.status === ActionStatus.PENDING;
  const isSearching = Boolean(beneficiaries.search) && isLoading;

  useScrollToTop();

  useEffect(() => {
    dispatch(fetchBeneficiariesAction());
  }, [dispatch]);

  const handleCloseFilters = () => {
    setIsFiltersVisible(!isFiltersVisible);
  };

  const handleSearchSubmit = (searchText: string) => {
    dispatch(setSearchAction(searchText));
    dispatch(
      fetchBeneficiariesAction({
        search: searchText,
      }),
    );
  };

  const handleSearchClear = () => {
    dispatch(clearSearchAction());
    dispatch(fetchBeneficiariesAction());
  };

  const getNoDataMessage = () => {
    if (isLoading) {
      return "";
    }

    if (beneficiaries.search) {
      return `No results for "${beneficiaries.search}"`;
    }

    if (activeFilterCount > 0) {
      return "No results for the selected filters";
    }

    return "There are no beneficiaries yet";
  };

  return (
    <Layout.Inset>
      <TabNavigation tabs={HOME_TABS} />

      <BeneficiariesTable
        count={beneficiaries.count}
        beneficiaries={beneficiaries.items}
        status={beneficiaries.status}
        emptyMessage={getNoDataMessage()}
        errorMessage={beneficiaries.errorString}
        onFetchMore={() => dispatch(fetchMoreBeneficiariesAction())}
        header={
          <TableHeader
            title={getPluralizedText({
              count: beneficiaries.count,
              singular: "beneficiary",
              plural: "beneficiaries",
            })}
            actionItems={
              <>
                <Search
                  id="beneficiaries-search"
                  placeholder="Search by phone number or account key"
                  onSubmit={handleSearchSubmit}
                  onClear={handleSearchClear}
                  isLoading={isSearching}
                />

                <Filter
                  isVisible={isFiltersVisible}
                  onClick={handleCloseFilters}
                  activeFilterCount={activeFilterCount}
                >
                  <FiltersBeneficiaries onClose={handleCloseFilters} />
                </Filter>
              </>
            }
          />
        }
      />
    </Layout.Inset>
  );
};
