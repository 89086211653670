import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "config/store";
import { fetchPaymentsStatus } from "helpers/fetchPaymentsStatus";
import { postPaymentsStatus } from "helpers/postPaymentsStatus";
import { getErrorMessage } from "helpers/getErrorMessage";
import { endSessionIfTokenInvalid } from "helpers/endSessionIfTokenInvalid";
import { ActionStatus, RejectMessage, PaymentsStatusInitialState } from "types";

export const paymentsStatusAction = createAsyncThunk<
  { isActive: boolean },
  undefined,
  { rejectValue: RejectMessage; state: RootState }
>(
  "paymentsStatus/paymentsStatusAction",
  async (_, { rejectWithValue, getState, dispatch }) => {
    const { token } = getState().userAccount;

    try {
      const response = await fetchPaymentsStatus({ token });
      endSessionIfTokenInvalid(response, dispatch);

      return {
        isActive: response,
      };
    } catch (error: any) {
      return rejectWithValue({
        errorString: getErrorMessage(error),
      });
    }
  },
);

export const updatePaymentsStatusAction = createAsyncThunk<
  { isActive: boolean },
  { isActive: boolean },
  { rejectValue: RejectMessage; state: RootState }
>(
  "paymentsStatus/updatePaymentsStatusAction",
  async ({ isActive }, { rejectWithValue, getState, dispatch }) => {
    const { token } = getState().userAccount;

    try {
      const response = await postPaymentsStatus({ token, isActive });
      endSessionIfTokenInvalid(response, dispatch);

      return {
        isActive: response,
      };
    } catch (error: any) {
      return rejectWithValue({
        errorString: getErrorMessage(error),
      });
    }
  },
);

const initialState: PaymentsStatusInitialState = {
  isActive: false,
  status: undefined,
  errorString: undefined,
};

const paymentsStatusSlice = createSlice({
  name: "paymentsStatus",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(paymentsStatusAction.pending, (state = initialState) => {
      state.status = ActionStatus.PENDING;
    });
    builder.addCase(paymentsStatusAction.fulfilled, (state, action) => {
      state.isActive = action.payload.isActive;
      state.status = ActionStatus.SUCCESS;
      state.errorString = "";
    });
    builder.addCase(paymentsStatusAction.rejected, (state, action) => {
      state.status = ActionStatus.ERROR;
      state.errorString = action.payload?.errorString;
    });

    builder.addCase(
      updatePaymentsStatusAction.pending,
      (state = initialState) => {
        state.status = ActionStatus.PENDING;
      },
    );
    builder.addCase(updatePaymentsStatusAction.fulfilled, (state, action) => {
      state.isActive = action.payload.isActive;
      state.status = ActionStatus.SUCCESS;
      state.errorString = "";
    });
    builder.addCase(updatePaymentsStatusAction.rejected, (state, action) => {
      state.status = ActionStatus.ERROR;
      state.errorString = action.payload?.errorString;
    });
  },
});

export const paymentsStatusSelector = (state: RootState) =>
  state.paymentsStatus;

export const { reducer } = paymentsStatusSlice;
