import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Table,
  TextLink,
  Pagination,
  Loader,
} from "@stellar/design-system";
import { Link } from "react-router-dom";

import { PaymentStatusTag } from "components/PaymentStatusTag";
import {
  STELLAR_EXPERT_URL,
  InfoLabel,
  PAYMENTS_PAGE_SIZE,
} from "constants/settings";
import { currency } from "helpers/formatAmount";
import { formatDateTime } from "helpers/formatDate";
import { getPluralizedText } from "helpers/getPluralizedText";
import { getCurrentPageItems } from "helpers/getCurrentPageItems";
import { PaymentItem, ActionStatus } from "types";

type PaymentsProps = {
  count: number;
  payments: PaymentItem[];
  status: ActionStatus | undefined;
  emptyMessage: string;
  errorMessage: string;
  onFetchMore: () => void;
};

export const Payments = ({
  count,
  payments,
  status,
  emptyMessage,
  errorMessage,
  onFetchMore,
}: PaymentsProps) => {
  const [currentPage, setCurrentPage] = useState<number | undefined>(undefined);
  const [currentItems, setCurrentItems] = useState<PaymentItem[]>([]);

  const isLoading = status === ActionStatus.PENDING;
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!isLoading) {
      const items = getCurrentPageItems({
        currentIndex: currentPage,
        allItems: payments,
        maxCount: count,
        pageSize: PAYMENTS_PAGE_SIZE,
        onFetchMore,
      });

      if (items) {
        setCurrentItems(items);

        if (currentPage) {
          containerRef.current?.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }, [isLoading, count, currentPage, onFetchMore, payments]);

  const labels = [
    {
      id: "disbursementId",
      label: <span title={InfoLabel.DUSBURS_ID}>Disburs. ID</span>,
    },
    {
      id: "completedAt",
      label: <span title={InfoLabel.COMPLETED_AT}>Completed At</span>,
      sortBy: true,
    },
    {
      id: "custodialTxId",
      label: (
        <span title={InfoLabel.CIRCLE_TRANSFER_ID}>Circle Transfer ID</span>
      ),
    },
    {
      id: "stellarTxId",
      label: (
        <span title={InfoLabel.STELLAR_TRANSACTION}>Stellar Transaction</span>
      ),
    },
    {
      id: "amount",
      label: <span title={InfoLabel.PAYMENT_AMOUNT}>Amount</span>,
      sortBy: true,
    },
    {
      id: "withdrawnAmount",
      label: <span title={InfoLabel.WITHDRAWN_BALANCE}>Withdrawn Amount</span>,
      sortBy: true,
    },
    {
      id: "status",
      label: <span title={InfoLabel.PAYMENT_STATUS}>Status</span>,
      sortBy: true,
    },
  ];

  const renderRow = (item: PaymentItem) => (
    <React.Fragment key={item.id}>
      <td className="DetailsTable__disbursId">
        <Link
          to={`/disbursements/${item.disbursementId}/beneficiaries`}
          className="TextLink TextLink--primary"
        >
          <span
            className="DetailsTable__ellipsis"
            title={item.disbursementId || ""}
          >
            {item.disbursementId}
          </span>
        </Link>
      </td>
      <td className="DetailsTable__nowrap">
        {item.completedAt ? formatDateTime(item.completedAt) : ""}
      </td>
      <td>
        <span
          className="DetailsTable__ellipsis"
          title={item.custodialTxId || ""}
        >
          {item.custodialTxId}
        </span>
      </td>
      <td>
        {item.stellarTxId ? (
          <TextLink
            href={`${STELLAR_EXPERT_URL}/${item.stellarTxId}`}
            title={item.stellarTxId}
          >
            <span className="DetailsTable__ellipsis">{item.stellarTxId}</span>
          </TextLink>
        ) : null}
      </td>
      <td className="DetailsTable__nowrap">
        <span className={`${item.status === "failed" ? "error" : ""}`}>
          {currency.format(Number(item.amount))}
        </span>
      </td>
      <td className="DetailsTable__nowrap">
        <span className={`${item.status === "failed" ? "error" : ""}`}>
          {item.withdrawalAmount
            ? currency.format(Number(item.withdrawalAmount))
            : ""}
        </span>
      </td>
      <td className="DetailsTable__nowrap">
        <PaymentStatusTag status={item.status} />
      </td>
    </React.Fragment>
  );

  return (
    <>
      {count === 0 && isLoading ? (
        <div className="InitialLoader">
          <Loader size="3rem" />
        </div>
      ) : null}

      {status === ActionStatus.SUCCESS ? (
        <div className="TableHeader__info">
          {count === 0
            ? emptyMessage
            : getPluralizedText({
                count,
                singular: "payment",
                plural: "payments",
              })}
        </div>
      ) : null}

      {status === ActionStatus.ERROR ? (
        <div className="error">{errorMessage}</div>
      ) : null}

      {count ? (
        <div className="DetailsTable Payments" ref={containerRef}>
          <Card>
            <Table
              id="beneficiaryPayments"
              data={currentItems}
              columnLabels={labels}
              renderItemRow={renderRow}
              breakpoint={900}
              hideNumberColumn
              isLoading={isLoading}
            />
            <Pagination
              pageSize={PAYMENTS_PAGE_SIZE}
              itemCount={count}
              currentPage={currentPage || 1}
              setCurrentPage={setCurrentPage}
            />
          </Card>
        </div>
      ) : null}
    </>
  );
};
