import { API_URL } from "constants/settings";
import { DisbursementRecord } from "types";

export const fetchDisbursementDetails = async (
  disbursementId: string,
  token: string,
): Promise<DisbursementRecord> => {
  const response = await fetch(`${API_URL}/disbursements/${disbursementId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.json();
};
