import { NavLink } from "react-router-dom";
import "./styles.scss";

type TabItem = {
  id: string;
  link: string;
  label: string;
};

export const TabNavigation = ({ tabs }: { tabs: TabItem[] }) => {
  const getStyleClass = (isActive: boolean) =>
    `TabNavigation__tabItem ${
      isActive ? "TabNavigation__tabItem--active" : ""
    }`;

  return (
    <div className="TabNavigation">
      {tabs.map((t) => (
        <NavLink
          key={t.id}
          to={t.link}
          className={({ isActive }) => getStyleClass(isActive)}
        >
          {t.label}
        </NavLink>
      ))}
    </div>
  );
};
