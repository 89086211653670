import { getItemStatus } from "helpers/getItemStatus";
import { PaymentItem, PaymentRecord } from "types";

export const formatPayments = (records: PaymentRecord[]): PaymentItem[] =>
  records.map((r) => ({
    id: r.id,
    accountId: r.account_id,
    completedAt: r.completed_at,
    custodialTxId: r.custodial_transaction_id,
    stellarTxId: r.stellar_transaction_id,
    amount: r.amount,
    disbursementId: r.disbursement_id,
    status: getItemStatus(r.status, r.withdrawal_status),
    withdrawalAmount: r.withdrawal_amount,
  }));
