import { API_URL } from "constants/settings";
import { BeneficiariesResponse, BeneficiaryOptions } from "types";

export const fetchBeneficiaries = async ({
  token,
  options = {},
}: {
  token: string;
  options?: BeneficiaryOptions;
}): Promise<BeneficiariesResponse> => {
  const searchParams =
    Object.keys(options).length > 0
      ? new URLSearchParams(options).toString()
      : undefined;

  const response = await fetch(`${API_URL}/accounts?${searchParams || ""}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.json();
};
