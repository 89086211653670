import { useEffect } from "react";
import { Card, Layout } from "@stellar/design-system";
import { useParams, Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { AppDispatch } from "config/store";
import { InfoLabel } from "constants/settings";
import { BeneficiaryStatusTag } from "components/BeneficiaryStatusTag";
import { Payments } from "components/Payments";
import { StellarAddress } from "components/StellarAddress";
import {
  fetchBeneficiaryDetailsAction,
  resetBeneficiaryDetailsAction,
} from "ducks/beneficiaryDetails";
import {
  fetchBeneficiaryPaymentsAction,
  fetchMoreBeneficiaryPaymentsAction,
  resetBeneficiaryPaymentsAction,
} from "ducks/beneficiaryPayments";

import { formatDateTime } from "helpers/formatDate";
import { currency } from "helpers/formatAmount";
import { formatUkrainianPhoneNumber } from "helpers/formatUkrainianPhoneNumber";
import { useRedux } from "hooks/useRedux";
import { useScrollToTop } from "hooks/useScrollToTop";

export const BeneficiaryDetails = () => {
  const { accountId } = useParams();
  const { beneficiaryDetails, beneficiaryPayments } = useRedux(
    "beneficiaryDetails",
    "beneficiaryPayments",
  );
  const dispatch: AppDispatch = useDispatch();
  const isRegistered = Boolean(beneficiaryDetails.key);

  useScrollToTop();

  useEffect(() => {
    if (accountId) {
      dispatch(fetchBeneficiaryDetailsAction({ accountId }));
      dispatch(fetchBeneficiaryPaymentsAction({ accountId }));
    }

    return () => {
      dispatch(resetBeneficiaryDetailsAction());
      dispatch(resetBeneficiaryPaymentsAction());
    };
  }, [accountId, dispatch]);

  return (
    <Layout.Inset>
      <div className="Breadcrumbs">
        <Link to="/beneficiaries" className="TextLink TextLink--primary">
          Beneficiaries
        </Link>
        <span className="Breadcrumbs__item">Beneficiary details</span>
      </div>

      <div className="TableHeader__info">
        <div>
          Beneficiary{" "}
          {formatUkrainianPhoneNumber(beneficiaryDetails.phoneNumber) || "-"}
        </div>
        <BeneficiaryStatusTag status={beneficiaryDetails.accountStatus} />
      </div>

      <div className="DetailsHeader">
        <Card>
          <div className="DetailsHeader__info">
            <div>
              <label title={InfoLabel.ACCOUNT_KEY}>Account Key</label>
              <div
                {...(beneficiaryDetails.key
                  ? { title: beneficiaryDetails.key }
                  : {})}
              >
                <StellarAddress address={beneficiaryDetails.key} />
              </div>
            </div>

            <div>
              <label title={InfoLabel.DATE_OF_BIRTH}>Birth (MM/YY)</label>
              <div className="DetailsHeader__info__value">
                {beneficiaryDetails.extraInfo
                  ? beneficiaryDetails.extraInfo.replace("-", "/")
                  : "-"}
              </div>
            </div>

            <div className="DetailsHeader__info--fullWidth">
              <label title={InfoLabel.EMAIL}>E-mail</label>
              <div className="DetailsHeader__info__value">
                {beneficiaryDetails.email ?? "-"}
              </div>
            </div>
          </div>
        </Card>

        <Card>
          <div className="DetailsHeader__stats">
            <div className="DetailsHeader__stats__item">
              <div title={InfoLabel.SMS_LAST_SENT}>SMS last sent</div>
              <div className="DetailsHeader__stats__item__value">
                {beneficiaryDetails.linkLastSentAt
                  ? formatDateTime(beneficiaryDetails.linkLastSentAt)
                  : "-"}
              </div>
            </div>

            <div className="DetailsHeader__stats__item">
              <div title={InfoLabel.FAILED_PAYM}>Failed payments</div>
              <div className="DetailsHeader__stats__item__value">
                {beneficiaryDetails.numFailedPayments}
              </div>
            </div>

            <div className="DetailsHeader__stats__item">
              <div title={InfoLabel.TOTAL_PAYM}>Total payments</div>
              <div className="DetailsHeader__stats__item__value">
                {beneficiaryDetails.numRequestedPayments}
              </div>
            </div>

            <div className="DetailsHeader__stats__item">
              <div title={InfoLabel.TOTAL_RECEIVED}>Total received</div>
              <div className="DetailsHeader__stats__item__value">
                {isRegistered
                  ? currency.format(
                      Number(beneficiaryDetails.amountTotalReceived),
                    )
                  : "-"}
              </div>
            </div>

            <div className="DetailsHeader__stats__item">
              <div title={InfoLabel.SUCCESSFUL_PAYM}>Successful payments</div>
              <div className="DetailsHeader__stats__item__value">
                {beneficiaryDetails.numSuccessfulPayments}
              </div>
            </div>

            <div className="DetailsHeader__stats__item">
              <div title={InfoLabel.WITHDRAWN_BALANCE}>Withdrawn balance</div>
              <div className="DetailsHeader__stats__item__value">
                {isRegistered && beneficiaryDetails.amountWithdrawnBalance
                  ? currency.format(
                      Number(beneficiaryDetails.amountWithdrawnBalance),
                    )
                  : "-"}
              </div>
            </div>
          </div>
        </Card>
      </div>

      <Payments
        count={beneficiaryPayments.count}
        payments={beneficiaryPayments.payments}
        status={beneficiaryPayments.status}
        emptyMessage="There are no payments to this beneficiary"
        errorMessage={beneficiaryPayments.errorString || ""}
        onFetchMore={() =>
          accountId &&
          dispatch(fetchMoreBeneficiaryPaymentsAction({ accountId }))
        }
      />
    </Layout.Inset>
  );
};
