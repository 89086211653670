import { API_URL } from "constants/settings";
import { AnyObject, BeneficiariesResponse } from "types";

export const fetchDisbursementBeneficiaries = async ({
  disbursementId,
  token,
  options = {},
}: {
  disbursementId: string;
  token: string;
  options?: AnyObject;
}): Promise<BeneficiariesResponse> => {
  const searchParams =
    Object.keys(options).length > 0
      ? new URLSearchParams(options).toString()
      : undefined;

  const response = await fetch(
    `${API_URL}/disbursements/${disbursementId}/accounts?${searchParams || ""}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.json();
};
