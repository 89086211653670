import { useState, useEffect } from "react";
import { Heading3, Modal, Button, Icon } from "@stellar/design-system";
import { useDispatch } from "react-redux";

import { AppDispatch } from "config/store";
import {
  paymentsStatusAction,
  updatePaymentsStatusAction,
} from "ducks/paymentsStatus";
import { useRedux } from "hooks/useRedux";
import "./styles.scss";
import { ActionStatus } from "types";

export const PaymentsSwitch = () => {
  const { paymentsStatus } = useRedux("paymentsStatus");
  const [isActive, setIsActive] = useState(paymentsStatus.isActive);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const dispatch: AppDispatch = useDispatch();
  const statusText = isActive ? "active" : "paused";
  const isLoading = paymentsStatus.status === ActionStatus.PENDING;
  const isError = paymentsStatus.status === ActionStatus.ERROR;

  useEffect(() => {
    dispatch(paymentsStatusAction());
  }, [dispatch]);

  useEffect(() => {
    setIsActive(paymentsStatus.isActive);
  }, [paymentsStatus.isActive]);

  const updatePaymentStatus = (toggle: boolean) => {
    dispatch(updatePaymentsStatusAction({ isActive: toggle }));
  };

  const renderStatusTag = () => {
    if (isError) {
      return (
        <>
          <div className="PaymentsSwitch__indicator PaymentsSwitch__indicator--error" />
          <div className="PaymentsSwitch__label error">
            Error getting payment status
          </div>
        </>
      );
    }

    return (
      <>
        <div
          className={`PaymentsSwitch__indicator PaymentsSwitch__indicator--${statusText}`}
        />
        <div className="PaymentsSwitch__label">Payments are {statusText}</div>
      </>
    );
  };

  const renderContent = () => {
    if (isError) {
      return (
        <div className="PaymentsSwitch__message">
          <div className="error">{paymentsStatus.errorString}</div>
        </div>
      );
    }

    if (isActive) {
      return (
        <>
          <div className="Tag Tag--success PaymentsSwitch__tag">
            <Icon.Play /> Payments are currently active
          </div>

          <div className="PaymentsSwitch__message">
            <div>
              Active means <strong>all current or new payments</strong> will
              start being processed automatically.
            </div>
            <div>
              Pausing payments means{" "}
              <strong>all pending and new payments</strong> will stop being
              processed automatically.
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="Tag Tag--error PaymentsSwitch__tag">
          <Icon.Pause /> Payments are currently paused
        </div>

        <div className="PaymentsSwitch__message">
          <div>
            Paused means <strong>no current or new payments</strong> will start
            being processed automatically.
          </div>
          <div>
            Activating payments means{" "}
            <strong>all pending and new payments</strong> will start being
            processed automatically.
          </div>
        </div>
      </>
    );
  };

  const renderButton = () => {
    if (isError) {
      return (
        <Button
          onClick={() => dispatch(paymentsStatusAction())}
          isLoading={isLoading}
        >
          Try again
        </Button>
      );
    }

    if (isActive) {
      return (
        <Button
          onClick={() => updatePaymentStatus(false)}
          data-pause
          isLoading={isLoading}
        >
          Pause Payments
        </Button>
      );
    }

    return (
      <Button onClick={() => updatePaymentStatus(true)} isLoading={isLoading}>
        Activate Payments
      </Button>
    );
  };

  return (
    <>
      <div className="PaymentsSwitch" onClick={() => setIsModalVisible(true)}>
        {renderStatusTag()}
      </div>

      <Modal visible={isModalVisible} onClose={() => setIsModalVisible(false)}>
        <Modal.Body>
          <Heading3>Payments status</Heading3>
          {renderContent()}
        </Modal.Body>

        <Modal.Footer>{renderButton()}</Modal.Footer>
      </Modal>
    </>
  );
};
