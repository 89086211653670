import { BeneficiaryItem, BeneficiaryRecord } from "types";

export const formatBeneficiaryItem = (
  record: BeneficiaryRecord,
): BeneficiaryItem => {
  // If there is no Stellar account, don't show current and total amounts. We
  // want to return them as empty strings rather than 0.
  const amountCurrentBalance = ""; // no longer exposed
  const amountTotalReceived = record.account
    ? record.payment_stats.total.amount
    : "";
  const amountWithdrawnBalance = record.account
    ? record.payment_stats.withdrawn?.amount
    : "";
  return {
    id: record.id,
    phoneNumber: record.phone_number,
    key: record.account,
    email: record.email,
    extraInfo: record.extra_info,
    linkLastSentAt: record.link_last_sent_at,
    numTotalPayments: record.payment_stats.total.count,
    numFailedPayments: record.payment_stats.failed.count,
    numSuccessfulPayments: record.payment_stats.successful.count,
    amountTotalReceived,
    amountCurrentBalance,
    amountWithdrawnBalance,
    accountStatus: record.status,
  };
};
