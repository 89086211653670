import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "config/store";
import { fetchBeneficiaryDetails } from "helpers/fetchBeneficiaryDetails";
import { formatBeneficiaryItem } from "helpers/formatBeneficiaryItem";
import { getErrorMessage } from "helpers/getErrorMessage";
import { endSessionIfTokenInvalid } from "helpers/endSessionIfTokenInvalid";
import {
  ActionStatus,
  RejectMessage,
  BeneficiaryDetailsInitialState,
  BeneficiaryRecord,
  BeneficiaryItem,
} from "types";

export const fetchBeneficiaryDetailsAction = createAsyncThunk<
  BeneficiaryItem,
  {
    accountId: string;
  },
  { rejectValue: RejectMessage; state: RootState }
>(
  "beneficiaryDetails/fetchBeneficiaryDetailsAction",
  async ({ accountId }, { rejectWithValue, getState, dispatch }) => {
    const { token } = getState().userAccount;

    try {
      const response: BeneficiaryRecord = await fetchBeneficiaryDetails(
        accountId,
        token,
      );

      endSessionIfTokenInvalid(response, dispatch);

      return formatBeneficiaryItem(response);
    } catch (error) {
      return rejectWithValue({
        errorString: getErrorMessage(error),
      });
    }
  },
);

const initialState: BeneficiaryDetailsInitialState = {
  id: "",
  phoneNumber: "",
  key: "",
  email: null,
  extraInfo: "",
  linkLastSentAt: null,
  numRequestedPayments: 0,
  numFailedPayments: 0,
  numSuccessfulPayments: 0,
  amountTotalReceived: "",
  amountCurrentBalance: "",
  amountWithdrawnBalance: "",
  accountStatus: "REGISTERED",
  status: undefined,
  errorString: undefined,
};

const beneficiaryDetailsSlice = createSlice({
  name: "beneficiaryDetails",
  initialState,
  reducers: {
    resetBeneficiaryDetailsAction: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchBeneficiaryDetailsAction.pending,
      (state = initialState) => {
        state.status = ActionStatus.PENDING;
      },
    );
    builder.addCase(
      fetchBeneficiaryDetailsAction.fulfilled,
      (state, action) => {
        state.id = action.payload.id;
        state.phoneNumber = action.payload.phoneNumber;
        state.key = action.payload.key;
        state.email = action.payload.email;
        state.extraInfo = action.payload.extraInfo;
        state.linkLastSentAt = action.payload.linkLastSentAt;
        state.numRequestedPayments = action.payload.numTotalPayments;
        state.numFailedPayments = action.payload.numFailedPayments;
        state.numSuccessfulPayments = action.payload.numSuccessfulPayments;
        state.amountTotalReceived = action.payload.amountTotalReceived;
        state.amountCurrentBalance = action.payload.amountCurrentBalance;
        state.amountWithdrawnBalance = action.payload.amountWithdrawnBalance;
        state.accountStatus = action.payload.accountStatus;
        state.status = ActionStatus.SUCCESS;
        state.errorString = "";
      },
    );
    builder.addCase(fetchBeneficiaryDetailsAction.rejected, (state, action) => {
      state.status = ActionStatus.ERROR;
      state.errorString = action.payload?.errorString;
    });
  },
});

export const beneficiaryDetailsSelector = (state: RootState) =>
  state.beneficiaryDetails;

export const { reducer } = beneficiaryDetailsSlice;
export const { resetBeneficiaryDetailsAction } =
  beneficiaryDetailsSlice.actions;
