import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "config/store";
import { fetchBeneficiaryPayments } from "helpers/fetchBeneficiaryPayments";
import { formatPayments } from "helpers/formatPayments";
import { getErrorMessage } from "helpers/getErrorMessage";
import { endSessionIfTokenInvalid } from "helpers/endSessionIfTokenInvalid";
import {
  ActionStatus,
  RejectMessage,
  PaymentsInitialState,
  PaymentItem,
} from "types";

export const fetchBeneficiaryPaymentsAction = createAsyncThunk<
  {
    payments: PaymentItem[];
    count: number;
    cursor: string | null;
  },
  {
    accountId: string;
  },
  { rejectValue: RejectMessage; state: RootState }
>(
  "beneficiaryPayments/fetchBeneficiaryPaymentsAction",
  async ({ accountId }, { rejectWithValue, getState, dispatch }) => {
    const { token } = getState().userAccount;

    try {
      const response = await fetchBeneficiaryPayments({ accountId, token });

      endSessionIfTokenInvalid(response, dispatch);
      const { payments, count, cursor } = response;

      return {
        payments: formatPayments(payments),
        count,
        cursor,
      };
    } catch (error) {
      return rejectWithValue({
        errorString: getErrorMessage(error),
      });
    }
  },
);

export const fetchMoreBeneficiaryPaymentsAction = createAsyncThunk<
  {
    payments: PaymentItem[];
    cursor: string | null;
  },
  {
    accountId: string;
  },
  { rejectValue: RejectMessage; state: RootState }
>(
  "beneficiaryPayments/fetchMoreBeneficiaryPaymentsAction",
  async ({ accountId }, { rejectWithValue, getState, dispatch }) => {
    const { token } = getState().userAccount;
    const { cursor: currentCursor } = getState().beneficiaryPayments;
    const options = {
      ...(currentCursor ? { cursor: currentCursor } : {}),
    };

    try {
      const response = await fetchBeneficiaryPayments({
        accountId,
        token,
        options,
      });

      endSessionIfTokenInvalid(response, dispatch);
      const { payments, cursor } = response;

      return {
        payments: formatPayments(payments),
        cursor,
      };
    } catch (error) {
      return rejectWithValue({
        errorString: getErrorMessage(error),
      });
    }
  },
);

const initialState: PaymentsInitialState = {
  payments: [],
  count: 0,
  cursor: null,
  status: undefined,
  errorString: undefined,
};

const beneficiaryPaymentsSlice = createSlice({
  name: "beneficiaryPayments",
  initialState,
  reducers: {
    resetBeneficiaryPaymentsAction: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchBeneficiaryPaymentsAction.pending,
      (state = initialState) => {
        state.status = ActionStatus.PENDING;
      },
    );
    builder.addCase(
      fetchBeneficiaryPaymentsAction.fulfilled,
      (state, action) => {
        state.payments = action.payload.payments;
        state.count = action.payload.count;
        state.cursor = action.payload.cursor;
        state.status = ActionStatus.SUCCESS;
        state.errorString = "";
      },
    );
    builder.addCase(
      fetchBeneficiaryPaymentsAction.rejected,
      (state, action) => {
        state.status = ActionStatus.ERROR;
        state.errorString = action.payload?.errorString;
      },
    );

    builder.addCase(
      fetchMoreBeneficiaryPaymentsAction.pending,
      (state = initialState) => {
        state.status = ActionStatus.PENDING;
      },
    );
    builder.addCase(
      fetchMoreBeneficiaryPaymentsAction.fulfilled,
      (state, action) => {
        state.payments = [...state.payments, ...action.payload.payments];
        state.cursor = action.payload.cursor;
        state.status = ActionStatus.SUCCESS;
        state.errorString = "";
      },
    );
    builder.addCase(
      fetchMoreBeneficiaryPaymentsAction.rejected,
      (state, action) => {
        state.status = ActionStatus.ERROR;
        state.errorString = action.payload?.errorString;
      },
    );
  },
});

export const beneficiaryPaymentsSelector = (state: RootState) =>
  state.beneficiaryPayments;

export const { reducer } = beneficiaryPaymentsSlice;
export const { resetBeneficiaryPaymentsAction } =
  beneficiaryPaymentsSlice.actions;
