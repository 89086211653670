import { API_URL } from "constants/settings";
import { DisbursementResponse, DisbursementOptions } from "types";

export const fetchDisbursements = async ({
  token,
  options = {},
}: {
  token: string;
  options?: DisbursementOptions;
}): Promise<DisbursementResponse> => {
  const searchParams =
    Object.keys(options).length > 0
      ? new URLSearchParams(options).toString()
      : undefined;

  const response = await fetch(
    `${API_URL}/disbursements?${searchParams || ""}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.json();
};
