import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { SESSION_EXPIRED, LOCAL_STORAGE_KEY } from "constants/settings";
import { restoreSessionAction } from "ducks/userAccount";
import { parseJwt } from "helpers/parseJwt";
import { useRedux } from "hooks/useRedux";

export const UserSession = () => {
  const { userAccount } = useRedux("userAccount");
  const dispatch = useDispatch();

  const isSessionExpired = userAccount.errorString === SESSION_EXPIRED;

  // Get token from local storage
  useEffect(() => {
    const sessionToken = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (!isSessionExpired && sessionToken) {
      const parsedToken = parseJwt(sessionToken);
      const username = parsedToken.user_id || "";

      dispatch(restoreSessionAction({ token: sessionToken, username }));
    }
  }, [dispatch, isSessionExpired]);

  // Save token to local storage
  useEffect(() => {
    if (userAccount.isAuthenticated && userAccount.token) {
      localStorage.setItem(LOCAL_STORAGE_KEY, userAccount.token);
    }
  }, [userAccount.isAuthenticated, userAccount.token]);

  // Clear local storage when session expired
  useEffect(() => {
    if (isSessionExpired) {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    }
  }, [isSessionExpired]);

  return null;
};
