import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import GitInfo from "generated/gitInfo";

import { store } from "config/store";
import { PrivateRoute } from "components/PrivateRoute";
import { InnerPage } from "components/InnerPage";
import { UserSession } from "components/UserSession";

import { Beneficiaries } from "pages/Beneficiaries";
import { BeneficiaryDetails } from "pages/BeneficiaryDetails";
import { Disbursements } from "pages/Disbursements";
import { DisbursementDetails } from "pages/DisbursementDetails";
import { NotFound } from "pages/NotFound";
import { SignIn } from "pages/SignIn";
import { SigninOidc } from "pages/Redirect";

import "styles.scss";

// TODO: update favicons

export const App = () => {
  useEffect(() => {
    // Git commit hash
    console.log("current commit hash: ", GitInfo.commitHash);
  }, []);

  return (
    <Provider store={store}>
      <UserSession />
      <BrowserRouter>
        <Routes>
          {/* Sign in */}
          <Route path="/" element={<SignIn />} />
          {/* Beneficiaries */}
          <Route
            path="/beneficiaries"
            element={
              <PrivateRoute>
                <InnerPage>
                  <Beneficiaries />
                </InnerPage>
              </PrivateRoute>
            }
          />
          {/* Beneficiary details */}
          <Route
            path="/beneficiaries/:accountId"
            element={
              <PrivateRoute>
                <InnerPage>
                  <BeneficiaryDetails />
                </InnerPage>
              </PrivateRoute>
            }
          />
          {/* Disbursements */}
          <Route
            path="/disbursements"
            element={
              <PrivateRoute>
                <InnerPage>
                  <Disbursements />
                </InnerPage>
              </PrivateRoute>
            }
          />
          {/* Disbursement details */}
          <Route
            path="/disbursements/:disbursementId/:sectionId"
            element={
              <PrivateRoute>
                <InnerPage>
                  <DisbursementDetails />
                </InnerPage>
              </PrivateRoute>
            }
          />
          {/* 404 */}
          <Route
            path="*"
            element={
              <InnerPage>
                <NotFound />
              </InnerPage>
            }
          />
          <Route path="/signin-oidc" element={<SigninOidc />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
};
