import { API_URL } from "constants/settings";
import { UserAccount } from "types";

export const fetchUserAccountToken = async (
  username: string,
  password: string,
): Promise<UserAccount> => {
  const response = await fetch(`${API_URL}/token`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username,
      password,
    }),
  });
  const responseJson = await response.json();

  if (responseJson.token) {
    return {
      username,
      token: responseJson.token,
    };
  }

  throw responseJson;
};
