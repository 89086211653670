import { API_URL } from "constants/settings";

export const fetchPaymentsStatus = async ({
  token,
}: {
  token: string;
}): Promise<boolean> => {
  const response = await fetch(`${API_URL}/activate`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const responseJson = await response.json();
  return Boolean(responseJson?.is_active);
};
