import { Tag } from "@stellar/design-system";
import { ItemStatus } from "types";

export const PaymentStatusTag = ({ status }: { status: ItemStatus }) => {
  switch (status) {
    case "success":
      return (
        <div className="StatusTag">
          <Tag variant={Tag.variant.success}>Sent</Tag>
        </div>
      );
    case "failed":
      return (
        <div className="StatusTag">
          <Tag variant={Tag.variant.error}>Failed</Tag>
        </div>
      );
    case "pendingFunds":
      return (
        <div className="StatusTag">
          <Tag variant={Tag.variant.error}>Pending funds</Tag>
        </div>
      );
    case "pending":
      return (
        <div className="StatusTag">
          <Tag>Pending</Tag>
        </div>
      );
    case "partialCashOut":
      return (
        <div className="StatusTag">
          <Tag variant={Tag.variant.success}>Part. cashed out</Tag>
        </div>
      );
    case "fullCashOut":
      return (
        <div className="StatusTag">
          <Tag variant={Tag.variant.success}>Cashed out</Tag>
        </div>
      );
    default:
      return null;
  }
};
