import { sessionExpiredAction } from "ducks/userAccount";
import { USE_SSO } from "constants/settings";
import { singleUserStore } from "helpers/singleSingOn";

export const endSessionIfTokenInvalid = (
  response: any,
  dispatch: any,
): void => {
  if (response?.code === "token_not_valid") {
    if (USE_SSO) {
      singleUserStore().then(); // reset user store (from session storage)
    }
    dispatch(sessionExpiredAction());
    throw new Error("Session expired");
  }
};
