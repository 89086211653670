import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "config/store";
import { fetchDisbursementBeneficiaries } from "helpers/fetchDisbursementBeneficiaries";
import { formatBeneficiaryRecords } from "helpers/formatBeneficiaryRecords";
import { getErrorMessage } from "helpers/getErrorMessage";
import { endSessionIfTokenInvalid } from "helpers/endSessionIfTokenInvalid";
import { removeFalsyKeys } from "helpers/removeFalsyKeys";

import {
  ActionStatus,
  RejectMessage,
  BeneficiariesInitialState,
  BeneficiaryItem,
  BeneficiaryOptions,
  AnyObject,
} from "types";

export const fetchDisbursementBeneficiariesAction = createAsyncThunk<
  {
    items: BeneficiaryItem[];
    count: number;
    cursor: string | null;
  },
  {
    disbursementId: string;
    options?: BeneficiaryOptions;
  },
  { rejectValue: RejectMessage; state: RootState }
>(
  "disbursementBeneficiaries/fetchDisbursementBeneficiariesAction",
  async ({ disbursementId }, { rejectWithValue, getState, dispatch }) => {
    const { token } = getState().userAccount;

    try {
      const response = await fetchDisbursementBeneficiaries({
        disbursementId,
        token,
      });

      endSessionIfTokenInvalid(response, dispatch);
      const { accounts, count, cursor } = response;

      return {
        items: formatBeneficiaryRecords(accounts),
        count,
        cursor,
      };
    } catch (error) {
      return rejectWithValue({
        errorString: getErrorMessage(error),
      });
    }
  },
);

export const fetchMoreDisbursementBeneficiariesAction = createAsyncThunk<
  {
    items: BeneficiaryItem[];
    cursor: string | null;
  },
  string,
  { rejectValue: RejectMessage; state: RootState }
>(
  "disbursementBeneficiaries/fetchMoreDisbursementBeneficiariesAction",
  async (disbursementId, { rejectWithValue, getState, dispatch }) => {
    const { token } = getState().userAccount;
    const { cursor: currentCursor } = getState().disbursementBeneficiaries;
    const options = {
      ...(currentCursor ? { cursor: currentCursor } : {}),
    };

    try {
      const response = await fetchDisbursementBeneficiaries({
        disbursementId,
        token,
        options,
      });

      endSessionIfTokenInvalid(response, dispatch);
      const { accounts, cursor } = response;

      return {
        items: formatBeneficiaryRecords(accounts),
        cursor,
      };
    } catch (error) {
      return rejectWithValue({
        errorString: getErrorMessage(error),
      });
    }
  },
);

const initialState: BeneficiariesInitialState = {
  items: [],
  count: 0,
  cursor: null,
  filters: {},
  search: "",
  status: undefined,
  errorString: undefined,
};

const disbursementBeneficiariesSlice = createSlice({
  name: "disbursementBeneficiaries",
  initialState,
  reducers: {
    resetDisbursementBeneficiariesAction: () => initialState,
    setFiltersAction: (state, action: PayloadAction<AnyObject>) => {
      state.filters = removeFalsyKeys({ ...state.filters, ...action.payload });
    },
    clearFiltersAction: (state) => {
      state.filters = {};
    },
    setSearchAction: (state, action) => {
      state.search = action.payload;
    },
    clearSearchAction: (state) => {
      state.search = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchDisbursementBeneficiariesAction.pending,
      (state = initialState) => {
        state.status = ActionStatus.PENDING;
      },
    );
    builder.addCase(
      fetchDisbursementBeneficiariesAction.fulfilled,
      (state, action) => {
        state.items = action.payload.items;
        state.count = action.payload.count;
        state.cursor = action.payload.cursor;
        state.status = ActionStatus.SUCCESS;
        state.errorString = "";
      },
    );
    builder.addCase(
      fetchDisbursementBeneficiariesAction.rejected,
      (state, action) => {
        state.status = ActionStatus.ERROR;
        state.errorString = action.payload?.errorString;
      },
    );

    builder.addCase(
      fetchMoreDisbursementBeneficiariesAction.pending,
      (state = initialState) => {
        state.status = ActionStatus.PENDING;
      },
    );
    builder.addCase(
      fetchMoreDisbursementBeneficiariesAction.fulfilled,
      (state, action) => {
        state.items = [...state.items, ...action.payload.items];
        state.cursor = action.payload.cursor;
        state.status = ActionStatus.SUCCESS;
        state.errorString = "";
      },
    );
    builder.addCase(
      fetchMoreDisbursementBeneficiariesAction.rejected,
      (state, action) => {
        state.status = ActionStatus.ERROR;
        state.errorString = action.payload?.errorString;
      },
    );
  },
});

export const disbursementBeneficiariesSelector = (state: RootState) =>
  state.disbursementBeneficiaries;

export const { reducer } = disbursementBeneficiariesSlice;
export const { resetDisbursementBeneficiariesAction } =
  disbursementBeneficiariesSlice.actions;
