import {
  configureStore,
  isPlain,
  createAction,
  CombinedState,
} from "@reduxjs/toolkit";
import { combineReducers, Action } from "redux";
import BigNumber from "bignumber.js";

import { RESET_STORE_ACTION_TYPE } from "constants/settings";

import { reducer as userAccount } from "ducks/userAccount";
import { reducer as beneficiaries } from "ducks/beneficiaries";
import { reducer as beneficiaryDetails } from "ducks/beneficiaryDetails";
import { reducer as beneficiaryPayments } from "ducks/beneficiaryPayments";
import { reducer as disbursements } from "ducks/disbursements";
import { reducer as disbursementDetails } from "ducks/disbursementDetails";
import { reducer as disbursementPayments } from "ducks/disbursementPayments";
import { reducer as disbursementBeneficiaries } from "ducks/disbursementBeneficiaries";
import { reducer as paymentsStatus } from "ducks/paymentsStatus";

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const loggerMiddleware =
  (storeVal: any) => (next: any) => (action: Action<any>) => {
    console.log("Dispatching: ", action.type);
    const dispatchedAction = next(action);
    console.log("NEW STATE: ", storeVal.getState());
    return dispatchedAction;
  };

const isSerializable = (value: any) =>
  BigNumber.isBigNumber(value) || isPlain(value);

const reducers = combineReducers({
  userAccount,
  beneficiaries,
  beneficiaryDetails,
  beneficiaryPayments,
  disbursements,
  disbursementDetails,
  disbursementPayments,
  disbursementBeneficiaries,
  paymentsStatus,
});

export const resetStoreAction = createAction(RESET_STORE_ACTION_TYPE);

const rootReducer = (state: CombinedState<any>, action: Action) => {
  const newState = action.type === RESET_STORE_ACTION_TYPE ? undefined : state;
  return reducers(newState, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        isSerializable,
      },
    }).concat(loggerMiddleware),
});
