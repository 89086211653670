import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BigNumber from "bignumber.js";
import { RootState } from "config/store";
import { fetchDisbursementDetails } from "helpers/fetchDisbursementDetails";
import { getErrorMessage } from "helpers/getErrorMessage";
import { endSessionIfTokenInvalid } from "helpers/endSessionIfTokenInvalid";
import {
  ActionStatus,
  RejectMessage,
  DisbursementDetailsInitialState,
  DisbursementRecord,
  DisbursementDetails,
} from "types";

export const fetchDisbursementDetailsAction = createAsyncThunk<
  DisbursementDetails,
  {
    disbursementId: string;
  },
  { rejectValue: RejectMessage; state: RootState }
>(
  "disbursementDetails/fetchDisbursementDetailsAction",
  async ({ disbursementId }, { rejectWithValue, getState, dispatch }) => {
    const { token } = getState().userAccount;

    try {
      const response: DisbursementRecord = await fetchDisbursementDetails(
        disbursementId,
        token,
      );

      endSessionIfTokenInvalid(response, dispatch);

      return {
        createdAt: response.requested_at,
        numBeneficiaries: response.total_account_num,
        numBeneficiariesSMS: response.total_accounts_received_sms,
        numAccountsCreated: response.total_accounts_with_vibrant_account,
        numSuccessfulPayments: response.payment_stats.successful.count,
        numFailedPayments: response.payment_stats.failed.count,
        numRemainingPayments:
          Number(response.payment_stats.pending.count) +
          Number(response.payment_stats.requested.count),
        amountAveragePaymentPerAccount:
          response.payment_stats.total.count === 0
            ? "0"
            : new BigNumber(response.payment_stats.total.amount)
                .div(response.payment_stats.total.count)
                .toString(),
        amountTotal: response.payment_stats.total.amount,
        amountTotalPaid: response.payment_stats.successful.amount,
        amountWithdrawn: response.payment_stats.withdrawn.amount,
      };
    } catch (error) {
      return rejectWithValue({
        errorString: getErrorMessage(error),
      });
    }
  },
);

const initialState: DisbursementDetailsInitialState = {
  numBeneficiaries: 0,
  createdAt: "",
  numBeneficiariesSMS: 0,
  amountAveragePaymentPerAccount: "",
  numAccountsCreated: 0,
  numSuccessfulPayments: 0,
  numFailedPayments: 0,
  numRemainingPayments: 0,
  amountTotal: "",
  amountTotalPaid: "",
  amountWithdrawn: "",
  status: undefined,
  errorString: undefined,
};

const disbursementDetailsSlice = createSlice({
  name: "disbursementDetails",
  initialState,
  reducers: {
    resetDisbursementDetailsAction: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchDisbursementDetailsAction.pending,
      (state = initialState) => {
        state.status = ActionStatus.PENDING;
      },
    );
    builder.addCase(
      fetchDisbursementDetailsAction.fulfilled,
      (state, action) => {
        state.numBeneficiaries = action.payload.numBeneficiaries;
        state.createdAt = action.payload.createdAt;
        state.numBeneficiariesSMS = action.payload.numBeneficiariesSMS;
        state.amountAveragePaymentPerAccount =
          action.payload.amountAveragePaymentPerAccount;
        state.numAccountsCreated = action.payload.numAccountsCreated;
        state.numSuccessfulPayments = action.payload.numSuccessfulPayments;
        state.numFailedPayments = action.payload.numFailedPayments;
        state.numRemainingPayments = action.payload.numRemainingPayments;
        state.amountWithdrawn = action.payload.amountWithdrawn;
        state.amountTotal = action.payload.amountTotal;
        state.amountTotalPaid = action.payload.amountTotalPaid;
        state.status = ActionStatus.SUCCESS;
        state.errorString = "";
      },
    );
    builder.addCase(
      fetchDisbursementDetailsAction.rejected,
      (state, action) => {
        state.status = ActionStatus.ERROR;
        state.errorString = action.payload?.errorString;
      },
    );
  },
});

export const disbursementDetailsSelector = (state: RootState) =>
  state.disbursementDetails;

export const { reducer } = disbursementDetailsSlice;
export const { resetDisbursementDetailsAction } =
  disbursementDetailsSlice.actions;
