import { CopyText, Icon, TooltipPosition } from "@stellar/design-system";
import { shortenAccountKey } from "helpers/shortenAccountKey";

export const StellarAddress = ({ address }: { address: string | null }) =>
  address ? (
    <div className="DetailsTable__stellarAddress">
      <span className="monospaced" title={address}>
        {shortenAccountKey(address)}
      </span>{" "}
      <CopyText
        textToCopy={address}
        tooltipPosition={TooltipPosition.RIGHT}
        showTooltip
      >
        <Icon.Copy />
      </CopyText>
    </div>
  ) : (
    <span className="note">Not registered</span>
  );
