import { useEffect, useState } from "react";
import { Heading3, Input, Button, InfoBlock } from "@stellar/design-system";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { AppDispatch } from "config/store";
import { SESSION_EXPIRED, USE_SSO } from "constants/settings";
import { PageHeader } from "components/PageHeader";
import { signInAction } from "ducks/userAccount";
import { useRedux } from "hooks/useRedux";
import { ActionStatus } from "types";
import { signInRedirect } from "helpers/singleSingOn";

export const SignIn = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { userAccount } = useRedux("userAccount");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const isSessionExpired = userAccount.errorString === SESSION_EXPIRED;

  useEffect(() => {
    if (
      userAccount.status === ActionStatus.SUCCESS &&
      userAccount.isAuthenticated
    ) {
      navigate({ pathname: "/beneficiaries", search: location.search });
    }
  }, [
    userAccount.isAuthenticated,
    userAccount.status,
    location.search,
    navigate,
  ]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(signInAction({ username, password }));
  };

  return (
    <div className="SignIn">
      <div className="SignIn__side" />
      <div className="SignIn__sidebar">
        <PageHeader />
      </div>
      <div className="SignIn__form">
        <form onSubmit={handleSubmit}>
          <Heading3>Sign in</Heading3>
          {!USE_SSO && (
            <div>
              <Input
                id="si-username"
                label="Username"
                onChange={(e) => setUsername(e.target.value)}
              />
              <Input
                id="si-password"
                label="Password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          )}
          {isSessionExpired && (
            <InfoBlock variant={InfoBlock.variant.info}>
              Section expired, please sign in again
            </InfoBlock>
          )}
          {!isSessionExpired && userAccount.errorString && (
            <InfoBlock variant={InfoBlock.variant.error}>
              {userAccount.errorString}
            </InfoBlock>
          )}
          {USE_SSO ? (
            <Button type="button" onClick={signInRedirect}>
              Single Sign On
            </Button>
          ) : (
            <Button
              type="submit"
              disabled={!username || !password}
              isLoading={userAccount.status === ActionStatus.PENDING}
            >
              Sign in
            </Button>
          )}
        </form>
      </div>
      <div className="SignIn__side form" />
    </div>
  );
};
