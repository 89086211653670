import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "config/store";
import { fetchDisbursementPayments } from "helpers/fetchDisbursementPayments";
import { formatPayments } from "helpers/formatPayments";
import { getErrorMessage } from "helpers/getErrorMessage";
import { endSessionIfTokenInvalid } from "helpers/endSessionIfTokenInvalid";
import {
  ActionStatus,
  RejectMessage,
  PaymentsInitialState,
  PaymentItem,
} from "types";

export const fetchDisbursementPaymentsAction = createAsyncThunk<
  {
    payments: PaymentItem[];
    count: number;
    cursor: string | null;
  },
  {
    disbursementId: string;
  },
  { rejectValue: RejectMessage; state: RootState }
>(
  "disbursementPayments/fetchDisbursementPaymentsAction",
  async ({ disbursementId }, { rejectWithValue, getState, dispatch }) => {
    const { token } = getState().userAccount;

    try {
      const response = await fetchDisbursementPayments({
        disbursementId,
        token,
      });

      const { payments, count, cursor } = response;
      endSessionIfTokenInvalid(response, dispatch);

      return {
        payments: formatPayments(payments),
        count,
        cursor,
      };
    } catch (error) {
      return rejectWithValue({
        errorString: getErrorMessage(error),
      });
    }
  },
);

export const fetchMoreDisbursementPaymentsAction = createAsyncThunk<
  {
    payments: PaymentItem[];
    cursor: string | null;
  },
  {
    disbursementId: string;
  },
  { rejectValue: RejectMessage; state: RootState }
>(
  "disbursementPayments/fetchMoreDisbursementPaymentsAction",
  async ({ disbursementId }, { rejectWithValue, getState, dispatch }) => {
    const { token } = getState().userAccount;
    const { cursor: currentCursor } = getState().disbursementPayments;
    const options = {
      ...(currentCursor ? { cursor: currentCursor } : {}),
    };

    try {
      const response = await fetchDisbursementPayments({
        disbursementId,
        token,
        options,
      });

      const { payments, cursor } = response;
      endSessionIfTokenInvalid(response, dispatch);

      return {
        payments: formatPayments(payments),
        cursor,
      };
    } catch (error) {
      return rejectWithValue({
        errorString: getErrorMessage(error),
      });
    }
  },
);

const initialState: PaymentsInitialState = {
  payments: [],
  count: 0,
  cursor: null,
  status: undefined,
  errorString: undefined,
};

const disbursementPaymentsSlice = createSlice({
  name: "disbursementPayments",
  initialState,
  reducers: {
    resetDisbursementPaymentsAction: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchDisbursementPaymentsAction.pending,
      (state = initialState) => {
        state.status = ActionStatus.PENDING;
      },
    );
    builder.addCase(
      fetchDisbursementPaymentsAction.fulfilled,
      (state, action) => {
        state.payments = action.payload.payments;
        state.count = action.payload.count;
        state.cursor = action.payload.cursor;
        state.status = ActionStatus.SUCCESS;
        state.errorString = "";
      },
    );
    builder.addCase(
      fetchDisbursementPaymentsAction.rejected,
      (state, action) => {
        state.status = ActionStatus.ERROR;
        state.errorString = action.payload?.errorString;
      },
    );

    builder.addCase(
      fetchMoreDisbursementPaymentsAction.pending,
      (state = initialState) => {
        state.status = ActionStatus.PENDING;
      },
    );
    builder.addCase(
      fetchMoreDisbursementPaymentsAction.fulfilled,
      (state, action) => {
        state.payments = [...state.payments, ...action.payload.payments];
        state.cursor = action.payload.cursor;
        state.status = ActionStatus.SUCCESS;
        state.errorString = "";
      },
    );
    builder.addCase(
      fetchMoreDisbursementPaymentsAction.rejected,
      (state, action) => {
        state.status = ActionStatus.ERROR;
        state.errorString = action.payload?.errorString;
      },
    );
  },
});

export const disbursementPaymentsSelector = (state: RootState) =>
  state.disbursementPayments;

export const { reducer } = disbursementPaymentsSlice;
export const { resetDisbursementPaymentsAction } =
  disbursementPaymentsSlice.actions;
