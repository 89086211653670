import { API_URL } from "constants/settings";

export const postPaymentsStatus = async ({
  token,
  isActive,
}: {
  token: string;
  isActive: boolean;
}): Promise<boolean> => {
  const response = await fetch(`${API_URL}/activate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ toggle: `${isActive}` }),
  });
  const { toggle } = await response.json();
  return Boolean(toggle);
};
