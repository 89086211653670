import { useEffect } from "react";
import { Layout, Card } from "@stellar/design-system";
import { useParams, Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { AppDispatch } from "config/store";
import { InfoLabel } from "constants/settings";
import { TabNavigation } from "components/TabNavigation";
import { Payments } from "components/Payments";
import { Beneficiaries } from "components/Beneficiaries";
import {
  fetchDisbursementDetailsAction,
  resetDisbursementDetailsAction,
} from "ducks/disbursementDetails";
import {
  fetchDisbursementPaymentsAction,
  fetchMoreDisbursementPaymentsAction,
  resetDisbursementPaymentsAction,
} from "ducks/disbursementPayments";
import {
  fetchDisbursementBeneficiariesAction,
  fetchMoreDisbursementBeneficiariesAction,
  resetDisbursementBeneficiariesAction,
} from "ducks/disbursementBeneficiaries";

import { currency } from "helpers/formatAmount";
import { formatDateTime } from "helpers/formatDate";
import { getPluralizedText } from "helpers/getPluralizedText";
import { useRedux } from "hooks/useRedux";
import { useScrollToTop } from "hooks/useScrollToTop";
import { ActionStatus } from "types";

enum SectionId {
  BENEFICIARIES = "beneficiaries",
  PAYMENTS = "payments",
}

export const DisbursementDetails = () => {
  const { disbursementId, sectionId } = useParams();
  const {
    disbursementDetails,
    disbursementPayments,
    disbursementBeneficiaries,
  } = useRedux(
    "disbursementDetails",
    "disbursementPayments",
    "disbursementBeneficiaries",
  );
  const dispatch: AppDispatch = useDispatch();

  useScrollToTop();

  useEffect(() => {
    if (disbursementId) {
      dispatch(
        fetchDisbursementDetailsAction({
          disbursementId,
        }),
      );
    }

    return () => {
      dispatch(resetDisbursementDetailsAction());
    };
  }, [disbursementId, dispatch]);

  useEffect(() => {
    if (disbursementId) {
      if (sectionId === SectionId.BENEFICIARIES) {
        // TODO: add filters and search
        dispatch(
          fetchDisbursementBeneficiariesAction({
            disbursementId,
          }),
        );
      } else if (sectionId === SectionId.PAYMENTS) {
        dispatch(
          fetchDisbursementPaymentsAction({
            disbursementId,
          }),
        );
      }
    }

    return () => {
      dispatch(resetDisbursementPaymentsAction());
      dispatch(resetDisbursementBeneficiariesAction());
    };
  }, [disbursementId, sectionId, dispatch]);

  const renderHeader = () => {
    if (disbursementBeneficiaries.status === ActionStatus.SUCCESS) {
      return (
        <div className="TableHeader__info">
          {disbursementBeneficiaries.count === 0
            ? "There are no beneficiaries in this disbursement"
            : getPluralizedText({
                count: disbursementBeneficiaries.count,
                singular: "beneficiary",
                plural: "beneficiaries",
              })}
        </div>
      );
    }

    if (disbursementBeneficiaries.status === ActionStatus.ERROR) {
      return (
        <div className="error">{disbursementBeneficiaries.errorString}</div>
      );
    }

    return null;
  };

  const renderSection = () => {
    if (sectionId === SectionId.BENEFICIARIES) {
      return (
        <Beneficiaries
          count={disbursementBeneficiaries.count}
          beneficiaries={disbursementBeneficiaries.items}
          status={disbursementBeneficiaries.status}
          header={renderHeader()}
          onFetchMore={() =>
            disbursementId &&
            dispatch(fetchMoreDisbursementBeneficiariesAction(disbursementId))
          }
        />
      );
    }

    if (sectionId === SectionId.PAYMENTS) {
      return (
        <Payments
          count={disbursementPayments.count}
          payments={disbursementPayments.payments}
          status={disbursementPayments.status}
          emptyMessage="There are no payments in this disbursement"
          errorMessage={disbursementPayments.errorString || ""}
          onFetchMore={() =>
            disbursementId &&
            dispatch(
              fetchMoreDisbursementPaymentsAction({
                disbursementId,
              }),
            )
          }
        />
      );
    }

    return null;
  };

  return (
    <Layout.Inset>
      <div className="Breadcrumbs">
        <Link to="/disbursements" className="TextLink TextLink--primary">
          Disbursements
        </Link>
        <span className="Breadcrumbs__item">Disbursement details</span>
      </div>

      <div className="TableHeader__info">Disbursement {disbursementId}</div>

      <div className="DetailsHeader">
        <Card>
          <div className="DetailsHeader__info DetailsHeader__info--stacked">
            <div className="TableHeader__info">
              {getPluralizedText({
                count: disbursementDetails.numBeneficiaries,
                singular: "Beneficiary",
                plural: "Beneficiaries",
              })}
            </div>

            <div>
              <label title={InfoLabel.DISB_CREATED_AT}>Created At</label>
              <div className="DetailsHeader__info__value">
                {disbursementDetails.createdAt
                  ? formatDateTime(disbursementDetails.createdAt)
                  : "-"}
              </div>
            </div>
          </div>
        </Card>

        <Card>
          <div className="DetailsHeader__stats">
            <div className="DetailsHeader__stats__item">
              <div title={InfoLabel.DISB_BENEFICIARIES_SMS_WAS_SENT_TO}>
                Beneficiaries SMS was sent to
              </div>
              <div className="DetailsHeader__stats__item__value">
                {disbursementDetails.numBeneficiariesSMS}
              </div>
            </div>

            <div className="DetailsHeader__stats__item">
              <div title={InfoLabel.DISB_VIBRANT_ACCOUNTS_CREATED}>
                Vibrant accounts created
              </div>
              <div className="DetailsHeader__stats__item__value">
                {disbursementDetails.numAccountsCreated}
              </div>
            </div>

            <div className="DetailsHeader__stats__item">
              <div title={InfoLabel.DISB_SUCCESSFUL_PAYM}>
                Successful payments
              </div>
              <div className="DetailsHeader__stats__item__value">
                {disbursementDetails.numSuccessfulPayments}
              </div>
            </div>

            <div className="DetailsHeader__stats__item">
              <div title={InfoLabel.DISB_AVERAGE_PAYMENT_PER_ACCOUNT}>
                Average payment per account
              </div>
              <div className="DetailsHeader__stats__item__value">
                {currency.format(
                  Number(disbursementDetails.amountAveragePaymentPerAccount),
                )}
              </div>
            </div>

            <div className="DetailsHeader__stats__item">
              <div title={InfoLabel.DISB_FAILED_PAYM}>Failed payments</div>
              <div className="DetailsHeader__stats__item__value">
                {disbursementDetails.numFailedPayments}
              </div>
            </div>

            <div className="DetailsHeader__stats__item">
              <div title={InfoLabel.DISB_TOTAL}>Disbursement total</div>
              <div className="DetailsHeader__stats__item__value">
                {currency.format(Number(disbursementDetails.amountTotal))}
              </div>
            </div>

            <div className="DetailsHeader__stats__item">
              <div title={InfoLabel.REMAINING_PAYM}>Remaining payments</div>
              <div className="DetailsHeader__stats__item__value">
                {disbursementDetails.numRemainingPayments}
              </div>
            </div>

            <div className="DetailsHeader__stats__item">
              <div title={InfoLabel.DISB_DISBURSED}>Disbursed</div>
              <div className="DetailsHeader__stats__item__value">
                {currency.format(Number(disbursementDetails.amountTotalPaid))}
              </div>
            </div>

            <div className="DetailsHeader__stats__item">
              <div title="empty"></div>
              <div className="DetailsHeader__stats__item__value"></div>
            </div>

            <div className="DetailsHeader__stats__item">
              <div title={InfoLabel.DISB_WITHDRAWN}>Withdrawn</div>
              <div className="DetailsHeader__stats__item__value">
                {currency.format(Number(disbursementDetails.amountWithdrawn))}
              </div>
            </div>
          </div>
        </Card>
      </div>

      <TabNavigation
        tabs={[
          {
            id: "beneficiaries",
            link: `/disbursements/${disbursementId}/beneficiaries`,
            label: "Beneficiaries",
          },
          {
            id: "payments",
            link: `/disbursements/${disbursementId}/payments`,
            label: "Payments",
          },
        ]}
      />

      {renderSection()}
    </Layout.Inset>
  );
};
