/* eslint-disable camelcase */
import { OidcStandardClaims } from "oidc-client-ts";

declare global {
  interface Window {
    _env_: {
      API_URL: string;
      STELLAR_EXPERT_URL: string;
      USDC_ASSET_ISSUER: string;

      USE_SSO: boolean;
      OIDC_AUTHORITY: string;
      OIDC_CLIENT_ID: string;
      OIDC_REDIRECT_URI: string;
      OIDC_SCOPE: string;
      OIDC_USERNAME_MAPPING: keyof Pick<
        OidcStandardClaims,
        "name" | "preferred_username" | "nickname"
      >;
    };
  }
}

// Store
export enum ActionStatus {
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export interface RejectMessage {
  errorString: string;
}

export interface SingleSignOnError {
  error: string;
  error_description: string;
  error_uri: unknown;
  form: unknown;
  name: string;
  session_state: unknown;
  state: unknown;
  message: string;
  stack: string;
}

export type PartialSingleError = Partial<SingleSignOnError>;

export type UserAccount = { token: string; username: string };

export type UserAccountInitialState = {
  token: string;
  username: string;
  isAuthenticated: boolean;
  status: ActionStatus | undefined;
  errorString?: string;
};

export type BeneficiariesInitialState = {
  items: BeneficiaryItem[];
  count: number;
  cursor: string | null;
  filters: AnyObject;
  search: string;
  status: ActionStatus | undefined;
  errorString?: string;
};

export type BeneficiaryDetailsInitialState = {
  id: string | number;
  phoneNumber: string;
  key: string | null;
  email: string | null;
  extraInfo: string;
  linkLastSentAt: string | null;
  numRequestedPayments: number;
  numFailedPayments: number;
  numSuccessfulPayments: number;
  amountTotalReceived: string;
  amountCurrentBalance: string;
  amountWithdrawnBalance: string;
  accountStatus: BeneficiaryStatus;
  status: ActionStatus | undefined;
  errorString?: string;
};

export type PaymentsInitialState = {
  payments: PaymentItem[];
  count: number;
  cursor: string | null;
  status: ActionStatus | undefined;
  errorString?: string;
};

export type DisbursementDetailsInitialState = {
  numBeneficiaries: number;
  createdAt: string;
  numBeneficiariesSMS: number;
  amountAveragePaymentPerAccount: string;
  numAccountsCreated: number;
  numSuccessfulPayments: number;
  numFailedPayments: number;
  numRemainingPayments: number;
  amountTotal: string;
  amountTotalPaid: string;
  amountWithdrawn: string;
  status: ActionStatus | undefined;
  errorString?: string;
};

export type DisbursementsInitialState = {
  disbursements: DisbursementItem[];
  count: number;
  cursor: string | null;
  filters: AnyObject;
  search: string;
  status: ActionStatus | undefined;
  errorString?: string;
};

export type PaymentsStatusInitialState = {
  isActive: boolean;
  status: ActionStatus | undefined;
  errorString?: string;
};

export interface Store {
  userAccount: UserAccountInitialState;
  beneficiaries: BeneficiariesInitialState;
  beneficiaryDetails: BeneficiaryDetailsInitialState;
  beneficiaryPayments: PaymentsInitialState;
  disbursements: DisbursementsInitialState;
  disbursementPayments: PaymentsInitialState;
  disbursementDetails: DisbursementDetailsInitialState;
  disbursementBeneficiaries: BeneficiariesInitialState;
  paymentsStatus: PaymentsStatusInitialState;
}

export type StoreKey = keyof Store;

// Generic
export type AnyObject = {
  [key: string]: any;
};

export type RecordStatus =
  | "REQUESTED"
  | "PENDING"
  | "SUCCESS"
  | "FAILURE"
  | "PENDING_FUNDS";
export type ItemStatus =
  | "success"
  | "failed"
  | "pending"
  | "pendingFunds"
  | "partialCashOut"
  | "fullCashOut";
export type WithdrawalStatus =
  | "NONE"
  | "PARTIALLY_WITHDRAWN"
  | "FULLY_WITHDRAWN";
export type BeneficiaryStatus =
  | "REGISTERED"
  | "LINK_SENT"
  | "READY"
  | "PAID"
  | "PARTIALLY_CASHED_OUT"
  | "FULLY_CASHED_OUT";

export type Stat = {
  count: number;
  amount: string;
};

export type PaymentStats = {
  failed: Stat;
  pending: Stat;
  requested: Stat;
  successful: Stat;
  withdrawn: Stat;
  total: Stat;
};

// Beneficiary
export type BeneficiaryItem = {
  id: string | number;
  phoneNumber: string;
  key: string | null;
  email: string | null;
  extraInfo: string;
  linkLastSentAt: string | null;
  numTotalPayments: number;
  numFailedPayments: number;
  numSuccessfulPayments: number;
  amountTotalReceived: string;
  amountCurrentBalance: string;
  amountWithdrawnBalance: string;
  accountStatus: BeneficiaryStatus;
};

export type PaymentItem = {
  id: string;
  accountId: string;
  completedAt: string | null;
  custodialTxId: string | null;
  stellarTxId: string | null;
  amount: string;
  disbursementId: string;
  status: ItemStatus;
  withdrawalAmount: string | null;
};

export type DisbursementItem = {
  id: string;
  startedAt: string;
  numBeneficiaries: number;
  numSuccessfulPayments: number;
  numPendingPayments: number;
  numFailedPayments: number;
  amountDisbursed: string;
  amountTotal: string;
};

export type DisbursementDetails = {
  numBeneficiaries: number;
  createdAt: string;
  numBeneficiariesSMS: number;
  amountAveragePaymentPerAccount: string;
  numAccountsCreated: number;
  numSuccessfulPayments: number;
  numFailedPayments: number;
  numRemainingPayments: number;
  amountTotal: string;
  amountTotalPaid: string;
  amountWithdrawn: string;
};

// API response
export type BeneficiaryRecord = {
  account: string | null;
  extra_info: string;
  email: string | null;
  id: string;
  link_last_sent_at: string | null;
  payment_stats: PaymentStats;
  phone_number: string;
  registered_at: string;
  status: BeneficiaryStatus;
};

export type BeneficiariesResponse = {
  accounts: BeneficiaryRecord[];
  count: number;
  cursor: string | null;
};

export type PaymentRecord = {
  account_id: string;
  amount: string;
  completed_at: string | null;
  custodial_transaction_id: string;
  disbursement_id: string;
  id: string;
  requested_at: string;
  started_at: string | null;
  status: RecordStatus;
  stellar_transaction_id: string | null;
  withdrawal_status: WithdrawalStatus;
  withdrawal_amount: string | null;
};

export type PaymentsResponse = {
  payments: PaymentRecord[];
  count: number;
  cursor: string | null;
};

export type DisbursementRecord = {
  id: string;
  payment_stats: PaymentStats;
  requested_at: string;
  total_account_num: number;
  total_accounts_received_sms: number;
  total_accounts_with_vibrant_account: number;
};

export type DisbursementResponse = {
  disbursements: DisbursementRecord[];
  count: number;
  cursor: string | null;
};

export type BeneficiaryOptions = {
  cursor?: string;
  amount_received_min?: string;
  amount_received_max?: string;
  search?: string;
  status?: string;
};

export type DisbursementOptions = {
  cursor?: string;
  total_amount_min?: string;
  total_amount_max?: string;
  started_at_before?: string;
  started_at_after?: string;
  search?: string;
};
