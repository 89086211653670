import { API_URL } from "constants/settings";
import { BeneficiaryRecord } from "types";

export const fetchBeneficiaryDetails = async (
  accountId: string,
  token: string,
): Promise<BeneficiaryRecord> => {
  const response = await fetch(`${API_URL}/accounts/${accountId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.json();
};
