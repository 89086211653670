import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "config/store";
import { fetchDisbursements } from "helpers/fetchDisbursements";
import { formatDisbursementRecords } from "helpers/formatDisbursementRecords";
import { getErrorMessage } from "helpers/getErrorMessage";
import { endSessionIfTokenInvalid } from "helpers/endSessionIfTokenInvalid";
import { removeFalsyKeys } from "helpers/removeFalsyKeys";
import {
  ActionStatus,
  RejectMessage,
  DisbursementsInitialState,
  DisbursementItem,
  DisbursementOptions,
  AnyObject,
} from "types";

export const fetchDisbursementsAction = createAsyncThunk<
  {
    disbursements: DisbursementItem[];
    count: number;
    cursor: string | null;
  },
  DisbursementOptions | undefined,
  { rejectValue: RejectMessage; state: RootState }
>(
  "disbursements/fetchDisbursementsAction",
  async (options, { rejectWithValue, getState, dispatch }) => {
    const { token } = getState().userAccount;

    try {
      const response = await fetchDisbursements({
        token,
        options,
      });

      endSessionIfTokenInvalid(response, dispatch);
      const { disbursements, count, cursor } = response;

      return {
        disbursements: formatDisbursementRecords(disbursements),
        count,
        cursor,
      };
    } catch (error: any) {
      return rejectWithValue({
        errorString: getErrorMessage(error),
      });
    }
  },
);

export const fetchMoreDisbursementsAction = createAsyncThunk<
  {
    disbursements: DisbursementItem[];
    cursor: string | null;
  },
  undefined,
  { rejectValue: RejectMessage; state: RootState }
>(
  "disbursements/fetchMoreDisbursementsAction",
  async (_, { rejectWithValue, getState, dispatch }) => {
    const { token } = getState().userAccount;
    const { cursor: currentCursor, search, filters } = getState().disbursements;
    const options = {
      ...(currentCursor ? { cursor: currentCursor } : {}),
      search,
      ...filters,
    };

    try {
      const response = await fetchDisbursements({
        token,
        options,
      });

      endSessionIfTokenInvalid(response, dispatch);
      const { disbursements, cursor } = response;

      return {
        disbursements: formatDisbursementRecords(disbursements),
        cursor,
      };
    } catch (error: any) {
      return rejectWithValue({
        errorString: getErrorMessage(error),
      });
    }
  },
);

const initialState: DisbursementsInitialState = {
  disbursements: [],
  count: 0,
  cursor: null,
  filters: {},
  search: "",
  status: undefined,
  errorString: undefined,
};

const disbursementsSlice = createSlice({
  name: "disbursements",
  initialState,
  reducers: {
    resetDisbursementsAction: () => initialState,
    setFiltersAction: (state, action: PayloadAction<AnyObject>) => {
      state.filters = removeFalsyKeys({ ...state.filters, ...action.payload });
    },
    clearFiltersAction: (state) => {
      state.filters = {};
    },
    setSearchAction: (state, action) => {
      state.search = action.payload;
    },
    clearSearchAction: (state) => {
      state.search = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchDisbursementsAction.pending,
      (state = initialState) => {
        state.status = ActionStatus.PENDING;
      },
    );
    builder.addCase(fetchDisbursementsAction.fulfilled, (state, action) => {
      state.disbursements = action.payload.disbursements;
      state.count = action.payload.count;
      state.cursor = action.payload.cursor;
      state.status = ActionStatus.SUCCESS;
      state.errorString = "";
    });
    builder.addCase(fetchDisbursementsAction.rejected, (state, action) => {
      state.status = ActionStatus.ERROR;
      state.errorString = action.payload?.errorString;
    });

    builder.addCase(
      fetchMoreDisbursementsAction.pending,
      (state = initialState) => {
        state.status = ActionStatus.PENDING;
      },
    );
    builder.addCase(fetchMoreDisbursementsAction.fulfilled, (state, action) => {
      state.disbursements = [
        ...state.disbursements,
        ...action.payload.disbursements,
      ];
      state.cursor = action.payload.cursor;
      state.status = ActionStatus.SUCCESS;
      state.errorString = "";
    });
    builder.addCase(fetchMoreDisbursementsAction.rejected, (state, action) => {
      state.status = ActionStatus.ERROR;
      state.errorString = action.payload?.errorString;
    });
  },
});

export const disbursementsSelector = (state: RootState) => state.disbursements;

export const { reducer } = disbursementsSlice;
export const {
  resetDisbursementsAction,
  setFiltersAction,
  clearFiltersAction,
  setSearchAction,
  clearSearchAction,
} = disbursementsSlice.actions;
