import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "config/store";
import { GENERIC_ERROR_MESSAGE, SESSION_EXPIRED } from "constants/settings";
import { fetchUserAccountToken } from "helpers/fetchUserAccountToken";
import {
  ActionStatus,
  RejectMessage,
  UserAccountInitialState,
  UserAccount,
} from "types";
import { singleSignOnAction } from "ducks/singleSignOnUserAccount";

export const signInAction = createAsyncThunk<
  UserAccount,
  { username: string; password: string },
  { rejectValue: RejectMessage; state: RootState }
>(
  "userAccount/signInAction",
  async ({ username, password }, { rejectWithValue }) => {
    try {
      const response = await fetchUserAccountToken(username, password);
      return {
        token: response.token,
        username: response.username,
      };
    } catch (error: any) {
      return rejectWithValue({
        errorString: error.detail || GENERIC_ERROR_MESSAGE,
      });
    }
  },
);

const initialState: UserAccountInitialState = {
  token: "",
  username: "",
  isAuthenticated: false,
  status: undefined,
  errorString: undefined,
};

const userAccountSlice = createSlice({
  name: "userAccount",
  initialState,
  reducers: {
    resetUserAccountAction: () => initialState,
    sessionExpiredAction: (state) => {
      state.token = "";
      state.username = "";
      state.isAuthenticated = false;
      state.status = undefined;
      state.errorString = SESSION_EXPIRED;
    },
    restoreSessionAction: (
      state,
      action: PayloadAction<{ token: string; username: string }>,
    ) => {
      state.token = action.payload.token;
      state.username = action.payload.username;
      state.isAuthenticated = true;
      state.status = ActionStatus.SUCCESS;
      state.errorString = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signInAction.pending, (state = initialState) => {
      state.status = ActionStatus.PENDING;
    });
    builder.addCase(signInAction.fulfilled, (state, action) => {
      state.token = action.payload.token;
      state.username = action.payload.username;
      state.isAuthenticated = true;
      state.status = ActionStatus.SUCCESS;
      state.errorString = "";
    });
    builder.addCase(signInAction.rejected, (state, action) => {
      state.token = "";
      state.username = "";
      state.isAuthenticated = false;
      state.status = ActionStatus.ERROR;
      state.errorString = action.payload?.errorString;
    });

    builder.addCase(singleSignOnAction.pending, (state = initialState) => {
      state.status = ActionStatus.PENDING;
    });
    builder.addCase(singleSignOnAction.fulfilled, (state, action) => {
      state.token = action.payload?.token as string;
      state.username = action.payload?.username as string;
      state.isAuthenticated = true;
      state.status = ActionStatus.SUCCESS;
      state.errorString = "";
    });
    builder.addCase(singleSignOnAction.rejected, (state, action) => {
      state.token = "";
      state.username = "";
      state.isAuthenticated = false;
      state.status = ActionStatus.ERROR;
      state.errorString = action.payload?.error_description;
    });
  },
});

export const userAccountSelector = (state: RootState) => state.userAccount;

export const { reducer } = userAccountSlice;
export const {
  resetUserAccountAction,
  sessionExpiredAction,
  restoreSessionAction,
} = userAccountSlice.actions;
