import "./styles.scss";

export const TableHeader = ({
  title,
  actionItems,
}: {
  title: string;
  actionItems: React.ReactNode;
}) => (
  <div className="TableHeader">
    <div className="TableHeader__info">{title}</div>
    <div className="TableHeader__actions">{actionItems}</div>
  </div>
);
