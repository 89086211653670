import React from "react";
import { Layout } from "@stellar/design-system";
import { useDispatch } from "react-redux";
import { PageHeader } from "components/PageHeader";
import { LOCAL_STORAGE_KEY, USE_SSO } from "constants/settings";
import { resetUserAccountAction } from "ducks/userAccount";
import { useRedux } from "hooks/useRedux";
import { singleUserStore } from "helpers/singleSingOn";

export const InnerPage = ({ children }: { children: React.ReactElement }) => {
  const { userAccount } = useRedux("userAccount");
  const dispatch = useDispatch();

  const handleSignOut = () => {
    if (USE_SSO) {
      singleUserStore().then(); // reset user store (from session storage)
    }
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    dispatch(resetUserAccountAction());
  };

  return (
    <>
      <PageHeader
        username={userAccount.username}
        onSignOut={userAccount.isAuthenticated ? handleSignOut : undefined}
        showPaymentsSwitch={userAccount.isAuthenticated}
      />
      <Layout.Content>{children}</Layout.Content>
    </>
  );
};
